import moment from "moment/moment";

export const getFormattedReportDates = (reportDates) => {
  const startDate = moment(
    reportDates.split("-")[0].replaceAll(" ", ""),
    "DD.MM.YYYY"
  );
  const endDate = moment(
    reportDates.split("-")[1].replaceAll(" ", ""),
    "DD.MM.YYYY"
  );
  let dates;

  if (startDate.year() === endDate.year()) {
    dates = `${startDate.format("DD")}.${startDate.format(
      "MM"
    )}-${endDate.format("DD")}.${endDate.format("MM")}.${endDate.format("YY")}`;
  } else {
    dates = `${startDate.format("DD")}.${startDate.format(
      "MM"
    )}.${startDate.format("YY")}-${endDate.format("DD")}.${endDate.format(
      "MM"
    )}.${endDate.format("YY")}`;
  }

  return dates;
};
