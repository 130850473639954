<template>
  <default-table :columns="reportsColumns" :rows="tableProps.rows">
    <template #body-cell-report-name="{ props }">
      <cell-highlighted :value="props.value" :row="props.row" />
    </template>
    <template #body-cell-report-dates="{ props }">
      <cell-highlighted :value="datesInstructor(props.row)" :row="props.row" />
    </template>
    <template #body-cell-edit-report="{ props }">
      <cell-edit-report
        :promote-to-current="tableProps.promoteToCurrent"
        :remove="tableProps.remove"
        :report="props.row"
      />
    </template>
    <template #header-cell-edit-report="{ props }">
      <q-th style="width: 42px">
        {{ props.col.label }}
      </q-th>
    </template>
    <template #header-cell-checked="{ props }">
      <q-th style="width: 42px">
        <div style="display: flex; justify-content: center">
          <question-btn
            theme="light"
            :on-click="() => appAlert(props.col.label)"
          />
        </div>
      </q-th>
    </template>
    <template #body-cell-checked="{ props }">
      <q-td style="width: 42px">
        <q-btn v-if="props.value" round flat color="orange" icon="check" />
      </q-td>
    </template>
  </default-table>
</template>

<script setup>
import DefaultTable from "@/components/table/DefaultTable";
import CellEditReport from "@/pages/allReports/panels/routesList/components/routesListTable/cellEditReport/CellEditReport";
import CellHighlighted from "@/pages/allReports/panels/routesList/components/routesListTable/cellHighlighted/CellHighlighted";
import { reportsColumns } from "@/pages/allReports/panels/routesList/components/routesListTable/reportsColumns";
import { appAlert } from "@/components/appAlert/appAlert";
import QuestionBtn from "@/components/questionBtn/QuestionBtn.vue";

const tableProps = defineProps({
  rows: {
    type: Array,
    default: () => [],
  },
  remove: {
    type: Function,
    default: undefined,
  },
  promoteToCurrent: {
    type: Function,
    default: undefined,
  },
});

const datesInstructor = (row) => {
  return row.instructor ? row.dates + " (" + row.instructor + ")" : row.dates;
};

const onButtonCLick = () => {
  appAlert(
    'Галочка проставляется, если в отчете "Баланс на руках после передач" во всех валютах равно нулю'
  );
};
</script>
