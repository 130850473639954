<template>
  <text-header class="item">{{
    props.header || "Добавить/передать оплату"
  }}</text-header>
  <q-select
    :options="paymentData.instructors"
    :model-value="paymentData.payment.name"
    :option-label="getInstructorSelectLabel"
    class="item"
    :error="!paymentData.payment.name && state.isError"
    outlined
    use-input
    label="ФИО инструктора/куратора"
    @update:model-value="onInstructorUpdate"
    @filter="filter"
  />
  <div class="sum-header">
    <div class="sum-header-title">Сумма основной части дохода</div>
    <div class="sum-header-desc">
      <question-btn :on-click="getHintClick(hints.mainIncome)" />
    </div>
  </div>
  <payment-input
    :code="paymentData.payment.moneyCode"
    :codes="props.moneyCodes"
    :sum="paymentData.payment.sum"
    :sum-error="
      (!paymentData.payment.sum && state.isError) ||
      (paymentData.payment.sum < 0 && !props.isNegativeSum)
    "
    :money-code-error="!paymentData.payment.moneyCode && state.isError"
    :update-code="(code) => (paymentData.payment.moneyCode = code)"
    :update-sum="(sum) => (paymentData.payment.sum = sum)"
  />
  <div class="sum-header">
    <div class="sum-header-title">
      Сумма бонусной части дохода (не обязательно)
    </div>
    <div class="sum-header-desc">
      <question-btn :on-click="getHintClick(hints.bonusIncome)" />
    </div>
  </div>
  <payment-input
    :code="paymentData.payment.moneyCode"
    :codes="props.moneyCodes"
    :sum="paymentData.payment.bonusSum"
    :money-code-error="!paymentData.payment.moneyCode && state.isError"
    :update-code="(code) => (paymentData.payment.moneyCode = code)"
    :update-sum="(sum) => (paymentData.payment.bonusSum = Number(sum))"
  />
  <div class="sum-header">
    <div class="sum-header-title">
      Бонус за покупку раскладки (не обязательно)
    </div>
    <div class="sum-header-desc">
      <question-btn :on-click="getHintClick(hints.soldItemsIncome)" />
    </div>
  </div>
  <payment-input
    :code="paymentData.payment.moneyCode"
    :codes="props.moneyCodes"
    :sum="paymentData.payment.soldItemsSum"
    :money-code-error="!paymentData.payment.moneyCode && state.isError"
    :update-code="(code) => (paymentData.payment.moneyCode = code)"
    :update-sum="(sum) => (paymentData.payment.soldItemsSum = Number(sum))"
  />
  <q-input
    v-model="paymentData.payment.comment"
    :error="!paymentData.payment.comment && state.isError"
    type="textarea"
    outlined
    label="Комментарий"
    class="item"
  />
  <input-date
    :date="paymentData.payment.date"
    :on-date-change="onDateChange"
    :error="!paymentData.payment.date && state.isError"
  />
  <form-confirmation
    :dismiss-handler="closeModalPage"
    :confirm-handler="onPaymentConfirm"
    :is-confirm-button-disabled="isConfirmButtonDisabled"
    :on-disabled-button-click="activateError"
    class="item"
  />
</template>

<script setup>
import { closeModalPage } from "@/modalPages/helpers/closeModalPage";
import { store } from "@/store/store";
import { reactive, computed } from "vue";
import { getFormattedCurrentDate } from "@/helpers/reports/getFormattedCurrentDate";
import FormConfirmation from "@/components/fromConfirmation/FormConfirmation";
import PaymentInput from "@/components/paymentInput/PaymentInput";
import TextHeader from "@/components/textHeader/TextHeader";
import { cachedRequestInstructors } from "@/api/cachedRequests";
import InputDate from "@/components/inputDate/InputDate";
import { toCamelCase } from "@/helpers/toCamelCase";
import { paymentTableType } from "@/helpers/paymentTableType";
import QuestionBtn from "@/components/questionBtn/QuestionBtn.vue";
import { appAlert } from "@/components/appAlert/appAlert";
import { hints } from "@/modalPages/pages/modalInstrucotrsIncome/hints";

const onPaymentConfirm = () => {
  closeModalPage();
  props.saveData(paymentData.payment);
};

const getHintClick = (hint) => {
  return async () => {
    await appAlert(hint);
  };
};

const format = (instructors) => {
  return Array.from(
    instructors.map((instructor) => {
      const firstName = instructor?.firstName || "";
      const middleName = instructor?.middleName || "";
      const lastName = instructor?.lastName || "";

      instructor.name = `${firstName} ${middleName} ${lastName}`;

      return instructor;
    })
  );
};

const props = store.modalPages.props;

const payment = {
  name: String(),
  sum: String(),
  bonusSum: 0,
  soldItemsSum: 0,
  moneyCode: String(),
  comment: String(),
  date: getFormattedCurrentDate(),
  instructorId: String(),
  id: "",
};

const paymentData = reactive({
  payment: props?.payment ? { ...props?.payment } : payment,
  instructors: [],
});
let instructors = [];

const state = reactive({ isError: false });
const activateError = () => {
  if (isConfirmButtonDisabled.value) {
    state.isError = true;
  }
};

const onInstructorUpdate = async (instructor) => {
  const instructors = store.currentReport.outgoingPayments.find(
    (payment) => payment.label === "Доходы инструктора"
  ).payments;

  for (const targetInstructor of instructors) {
    if (String(targetInstructor.instructorId) === String(instructor?.id)) {
      await appAlert(
        `Нельзя создать доход для <b>${instructor?.name}</b>. Для него уже указан доход. Выберите другого инструктора или отредактируйте этого`
      );
      return;
    }
  }

  paymentData.payment.id = instructor || "";
  paymentData.payment.name = instructor?.name || "";
  paymentData.payment.instructorId = instructor?.id || "";
};

const getInstructorSelectLabel = (instructor) => instructor.name || instructor;

const isConfirmButtonDisabled = computed(() => {
  return !(
    paymentData.payment.sum &&
    paymentData.payment.date &&
    paymentData.payment.moneyCode &&
    paymentData.payment.comment &&
    (paymentData.payment.name ||
      (props?.type !== paymentTableType.instructors &&
        props?.type !== paymentTableType.instructorsRevenue &&
        props?.type !== paymentTableType.tutors)) &&
    (paymentData.payment.sum >= 0 || props.isNegativeSum)
  );
});

const onDateChange = (date) => {
  paymentData.payment.date = date;
};

async function filter(inputValue, update) {
  if (paymentData.instructors.length) {
    paymentData.instructors = instructors.filter((instructor) =>
      `${instructor.firstName} ${instructor.middleName} ${instructor.lastName}`
        .toLowerCase()
        .includes(inputValue.toLowerCase())
    );
    update();
    return;
  }

  instructors = format(await cachedRequestInstructors());

  update(() => {
    paymentData.instructors = instructors;
  });
}
</script>

<style scoped>
.sum-header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 8px;
}

.sum-header-title {
  margin-right: 8px;
  font-weight: 700;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  padding: 0;
  line-height: 1;
}
</style>
