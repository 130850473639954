import { checklistSelectOptions } from "@/helpers/checklistSelectOptions";

export const getEmptyChecklist = () => {
  return [
    {
      header: "Регистрация в МЧС",
      hint: "Комментарии, номер группы",
      result: checklistSelectOptions[0],
      comment: "",
    },
    {
      header: "ТБ фото отправлено на tb@vpoxod.ru",
      hint: "Комментарии",
      result: checklistSelectOptions[0],
      comment: "",
    },
    {
      header: "Все чеки отправлены на согласованную заранее почту",
      hint: "Комментарии",
      result: checklistSelectOptions[0],
      comment: "",
    },
    {
      header: "Общественное снаряжение",
      hint: "Комментарии",
      result: checklistSelectOptions[0],
      customOptions: [
        "Не указано",
        "Перенести на поход (указать в комментарии на какой именно)",
        "Снаряжение будет возвращено в офис (указать какой СПБ или МСК)",
        "Будет передано другому инструктору (указать в комментариях кому именно)",
        "Частично будет перенесено или возвращено (подробности в коментариях)",
        "Не требуется",
      ],
      comment: "",
    },
    {
      header: "Все прошло хорошо",
      hint: "Комментарии",
      result: checklistSelectOptions[0],
      comment: "",
    },
    {
      header: "Фотоальбом есть",
      hint: "Комментарии, ссылки",
      result: checklistSelectOptions[0],
      comment: "",
    },
    {
      header: "Посты в соцсетях есть",
      hint: "Комментарии, ссылки",
      result: checklistSelectOptions[0],
      comment: "",
    },
  ];
};
