<template>
  <q-tabs
    v-model="targetTabIndex"
    no-caps
    stretch
    dense
    align="center"
    mobile-arrows
    active-class="text-orange"
  >
    <q-route-tab
      content-class="q-tab-content"
      v-for="(panel, index) in panels"
      :key="panel.label + index"
      :name="index"
      :icon="panel.icon"
      :to="`${routes.current.path}/${panel.route.path}`"
      :disable="isTabDisable(index)"
      :ripple="false"
    >
      <div class='q-tab-label'>
        {{ panel.label }}
      </div>
    </q-route-tab>
  </q-tabs>
  <section class="content">
    <router-view v-if="store.currentReport"> </router-view>
    <EmptyReport v-else />
  </section>
</template>

<script setup>
import { onMounted, ref, watch, watchEffect } from "vue";
import { childRoutes } from "@/pages/currentReport/childRoutes";
import { routes } from "@/router/router";
import { store } from "@/store/store";
import EmptyReport from "@/pages/currentReport/EmptyReport";
import { isTabDisable } from "@/helpers/reports/isTabDisable";
import { countReportBalance } from "@/helpers/reports/countReportBalance";

const panels = [
  {
    route: childRoutes.common,
    icon: "info",
    label: "Общее",
  },
  {
    route: childRoutes.payments,
    icon: "group_add",
    label: "Получено",
  },
  {
    route: childRoutes.costs,
    icon: "shopping_cart",
    label: "Расходы",
  },
  {
    route: childRoutes.instructorsRevenue,
    icon: "savings",
    label: "Доходы инструктора",
  },
  {
    route: childRoutes.conversion,
    icon: "currency_exchange",
    label: "Конвертация",
  },
  {
    route: childRoutes.checkList,
    icon: "done_all",
    label: "Чеклист",
  },
  {
    route: childRoutes.summary,
    icon: "calculate",
    label: "Итог",
  },
];

const targetTabIndex = ref(0);

watchEffect(() => {
  store.currentReport.balance = countReportBalance(store.currentReport);
});

onMounted(() => {
  store.currentReport?.moneyCodes.forEach((moneyCode) => {
    if (!!store.currentReport.moneySums[moneyCode]) {
      return;
    }

    store.currentReport.moneySums[moneyCode] = 0;
  });
});

watch(store.currentReport?.moneyCodes, () => {
  store.currentReport?.moneyCodes.forEach((moneyCode) => {
    if (!!store.currentReport.moneySums[moneyCode]) {
      return;
    }

    store.currentReport.moneySums[moneyCode] = 0;
  });
});
</script>

<style scoped>
.content {
  padding: 1em;
}

.q-tab {
  width: calc(100% / 7);
  overflow: hidden;
  padding-left: 0;
  padding-right: 0;
}

.q-tab-label {
  font-size: 12px;
  width: 100%;
  overflow:hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  line-height: 1;
}

@media screen and (max-width: 600px) {
  .q-tab-label {
    font-size: 10px;
  }
}

@media screen and (max-width: 400px) {
  .q-tab-label {
    font-size: 8px;
  }
}
</style>
