import script from "./RoutesListTable.vue?vue&type=script&setup=true&lang=js"
export * from "./RoutesListTable.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QTh from 'quasar/src/components/table/QTh.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTh,QTd,QBtn});
