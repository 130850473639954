<template>
  <text-header class="item">{{ props.header }}</text-header>
  <section v-for="infoItem in info" :key="infoItem.title" class="q-mb-md">
    <title-label :title="infoItem.header" :label="infoItem.label" />
  </section>
</template>

<script setup>
import { store } from "@/store/store";
import TextHeader from "@/components/textHeader/TextHeader";
import TitleLabel from "@/components/titleLabel/TitleLabel.vue";
import { computed } from "vue";

const props = store.modalPages.props;

const info = computed(() => {
  const row = props.row;

  if (props.rowNames) {
    return Object.entries(props.rowNames)
      .map(([key]) => {
        if (props.rowNames && props.rowNames[key] !== undefined) {
          return {
            label: row[key],
            header: props.rowNames[key],
          };
        }
      })
      .filter((res) => !!res);
  }

  return Object.entries(row)
    .map(([key, value]) => {
      if (props.rowNames && props.rowNames[key] !== undefined) {
        return {
          label: value,
          header: props.rowNames[key],
        };
      }
      const targetColumn = props.columns.find(({ field }) => field === key);

      if (!targetColumn) {
        return;
      }

      return {
        label: value,
        header: targetColumn?.label || "Неизвестное поле",
      };
    })
    .filter((res) => !!res);
});
</script>
