export const countRevenueForMoneyCode = (currentReport, code) => {
  let result = currentReport.moneySums[code]
    ? Number(currentReport.moneySums[code])
    : 0;

  for (let index = 1; index < currentReport.incomingPayments.length; index++) {
    for (let payment of currentReport.incomingPayments[index].payments) {
      if (payment.moneyCode === code) {
        result += Number(payment.sum);
      }
    }
  }

  return result;
};
