import { getPaymentsResult } from "@/helpers/getPaymentsResult";
import { createHeaderCell, styleSheet } from "@/helpers/excel/helpers";

export const setPaymentsResult = (
  sheet,
  payments,
  style = true,
  header,
  color
) => {
  const rowStart = sheet.rowCount + 1;
  let rowIndex = rowStart;

  if (header) {
    createHeaderCell(sheet.getCell(rowIndex, 1), header, color);
  }
  createHeaderCell(sheet.getCell(rowIndex, 2), "Итого");
  rowIndex += 1;

  const paymentsResults = getPaymentsResult(payments);

  for (const { moneyCode, sum } of paymentsResults) {
    sheet.getCell(rowIndex, 3).value = sum;
    sheet.getCell(rowIndex, 4).value = moneyCode;

    rowIndex += 1;
  }

  if (style) {
    styleSheet(sheet, rowStart, 6);
  }
};
