<template>
  <text-header>
    {{ `Отчет ${props.name}` }}
  </text-header>
  <q-input outlined class="item" :model-value="props.reportLink" disable />
  <div class="flex justify-center item">
    <q-btn no-caps color="orange" @click="onOpenLinkClick">
      Открыть ссылку в браузере
    </q-btn>
  </div>
</template>

<script setup>
import { store } from "@/store/store";
import TextHeader from "@/components/textHeader/TextHeader";
import { openInBrowser } from "@/helpers/openInBrowser";

const props = store.modalPages.props;

const onOpenLinkClick = () => {
  openInBrowser(props.reportLink);
};
</script>

<style scoped>
.item {
  margin-bottom: 20px;
}
</style>
