<template>
  <div class="main-report-table">
    <default-table :columns="tableColumns" :rows="tableRows">
      <template #header-cell="{ props }">
        <q-th :style="props.col.style">
          {{ props.col.label }}
        </q-th>
      </template>
      <template #body-cell="{ props }">
        <cell-filled :value="props.value" />
      </template>
      <template #body-cell-name="{ props }">
        <q-td>
          <div class="cell">
            <div class="title">
              {{ props.value }}
            </div>
          </div>
        </q-td>
      </template>
      <template #body-cell-description="{ props }">
        <q-td>
          <div class="cell">
            <question-btn :on-click="() => appAlert(props.value)" />
          </div>
        </q-td>
      </template>
    </default-table>
  </div>
</template>

<script setup>
import DefaultTable from "@/components/table/DefaultTable";
import { computed } from "vue";
import { store } from "@/store/store";
import { appAlert } from "@/components/appAlert/appAlert";
import CellFilled from "@/components/table/cellFilled/cellFilled.vue";
import QuestionBtn from "@/components/questionBtn/QuestionBtn.vue";

const tableColumns = computed(() => {
  if (!store.currentReport.balance) {
    return [];
  }

  const columns = [
    {
      align: "center",
      field: "description",
      name: "description",
      style: "min-width: 25px; width: 40px; white-space: break-spaces",
    },
    {
      field: "name",
      name: "name",
      style:
        "min-width: 100px; width: calc(50% - 40px); white-space: break-spaces",
    },
  ];

  const moneyCodeWidth =
    store.currentReport.balance.length >= 3
      ? 50 / 3
      : 50 / store.currentReport.balance.length;

  store.currentReport.balance.forEach(({ moneyCode }) => {
    columns.push({
      name: moneyCode,
      field: moneyCode,
      label: moneyCode,
      style: `min-width: 30px; width: ${moneyCodeWidth}%; white-space: break-spaces`,
    });
  });

  return columns;
});

// const moneyCodeCells = computed(() => {
//   return store.currentReport.balance.map(({ moneyCode }) => {
//     return `body-cell-${moneyCode}`;
//   });
// });

const tableRows = computed(() => {
  const rows = [
    {
      name: "Всего получено",
      fieldName: "revenue",
      description:
        "Общая сумма оплат участников* + получено от инструкторов/кураторов/офиса<br><br>* Сумма уже включает оплаты с искплючениями",
    },
    {
      name: "Всего расходов",
      fieldName: "expenses",
      description: "Общая сумма расходов по всем категориям",
    },
    {
      name: "Всего по прибыли инструктора(ов)",
      fieldName: "instructorsRevenue",
      description: "Общая сумма доходов всех инструкторов",
    },
    {
      name: "Итого остаток на руках",
      fieldName: "resultWithIncomingPayments",
      description:
        "Общая сумма оплат участников* + получено от инструкторов/кураторов/офиса - расходы - доходы инструктора, и все это с учетом конвертации",
    },
    {
      name: "Итого остаток на руках после передач",
      fieldName: "finalResult",
      description:
        "Итого остаток на руках - минусуем передано инструкторам/кураторам/офису",
    },
  ];

  return rows.map((row) => {
    for (const balanceItem of store.currentReport.balance) {
      row[balanceItem.moneyCode] = balanceItem[row.fieldName];
    }

    return row;
  });
});
</script>

<style>
.main-report-table {
  overflow-x: auto;
  max-width: 100%;
  margin-top: 20px;
}

.main-report-table .cell {
  width: 100%;
  height: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
  text-overflow: ellipsis;
  white-space: break-spaces;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: center;
}

.main-report-table .title {
  font-weight: bold;
  color: grey;
}
</style>
