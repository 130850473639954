<template>
  <text-header>
    {{ props.header || "Редактировать сборы участников" }}</text-header
  >
  <payment-input
    :update-sum="updateSum"
    :sum="data.sum"
    :code="props.moneyCode"
    :sum-error="data.isError && data.sum === props.sum"
  />
  <form-confirmation
    :dismiss-handler="closeModalPage"
    :confirm-handler="onConfirm"
    :is-confirm-button-disabled="isConfirmButtonDisabled"
    :on-disabled-button-click="activateError"
  />
</template>

<script setup>
import { store } from "@/store/store";
import { computed, reactive } from "vue";
import { closeModalPage } from "@/modalPages/helpers/closeModalPage";
import FormConfirmation from "@/components/fromConfirmation/FormConfirmation";
import TextHeader from "@/components/textHeader/TextHeader";
import PaymentInput from "@/components/paymentInput/PaymentInput.vue";

const props = store.modalPages.props;
const data = reactive({
  sum: props.sum ?? 0,
  isError: false,
});

const isConfirmButtonDisabled = computed(() => {
  return data.sum === props.sum;
});

const activateError = () => {
  data.isError = true;
};

const onConfirm = () => {
  if (!props.saveData) {
    return;
  }

  props.saveData(data.sum);
  closeModalPage();
};

const updateSum = (value) => {
  data.sum = Math.abs(value);
};
</script>
