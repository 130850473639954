export function fillEmptyCells(sheet, startRow, columns) {
  const { rowCount, columnCount } = sheet;
  for (let rowIndex = startRow ?? 1; rowIndex <= rowCount; rowIndex++) {
    for (
      let columnIndex = 1;
      columnIndex <= columns ?? columnCount;
      columnIndex++
    ) {
      if (sheet.getCell(rowIndex, columnIndex).value !== null) {
        continue;
      }

      sheet.getCell(rowIndex, columnIndex).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "d9d9d9" },
      };
      sheet.getCell(rowIndex, columnIndex).border = {
        top: { style: "thin", color: { argb: "c0c0c0" } },
        left: { style: "thin", color: { argb: "c0c0c0" } },
        bottom: { style: "thin", color: { argb: "c0c0c0" } },
        right: { style: "thin", color: { argb: "c0c0c0" } },
      };
    }
  }
}
