<template>
  <div class="relative-position">
    <q-input
      outlined
      type="number"
      :model-value="props.value"
      :error="props.error"
      :label="props.label"
      @update:model-value="onUpdate"
    >
    </q-input>
    <calculator-modal
      :close="toggleModal"
      :on-confirm="onConfirm"
      :is-open="state.isOpen"
    />
    <q-icon
      name="calculate"
      color="orange"
      class="icon text-orange-6"
      size="md"
      @click="toggleModal"
    />
  </div>
</template>

<script setup>
import CalculatorModal from "@/components/calculator/CalculatorModal.vue";
import { reactive } from "vue";

const props = defineProps({
  value: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    default: "Сумма",
  },
  onValueChange: {
    type: Function,
    required: true,
  },
  error: {
    type: Boolean,
    default: () => false,
  },
});

const state = reactive({
  isOpen: false,
});

const toggleModal = () => {
  state.isOpen = !state.isOpen;
};

const onUpdate = (newValue) => {
  props.onValueChange(newValue);
};

const onConfirm = ({ countedValue }) => {
  state.isOpen = false;
  if (countedValue) {
    props.onValueChange(countedValue);
  }
};
</script>

<style scoped>
.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: all ease-in 200ms;
  cursor: pointer;
  position: absolute;
  right: 4px;
  border: solid 1px transparent;
  top: calc(50% - 30px);
}

.icon:active {
  background: rgba(128, 128, 128, 0.4);
  border: solid 1px rgba(128, 128, 128, 0.6);
}

@media (hover: hover) and (pointer: fine) {
  .icon:hover {
    background: rgba(128, 128, 128, 0.4);
    border: solid 1px rgba(128, 128, 128, 0.6);
  }
}
</style>
