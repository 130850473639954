export const getModalHeader = ({ value, isEdit, type }) => {
  if (type === "members" && isEdit) {
    return "Оплаты участников с исключениями";
  } else if (type === "members" && !isEdit) {
    return "Редактировать оплату участника с исключением";
  }

  if (isEdit) {
    return `Изменить ${value}`;
  }

  return `Добавить ${value}`;
};
