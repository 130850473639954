<template>
  <q-td style="width: 42px">
    <q-btn round flat color="grey" icon="more_horiz">
      <q-menu
        v-if="store.currentReport?.key !== report?.key"
        style="width: 150px"
      >
        <q-list>
          <q-item
            v-close-popup
            class="flex-center"
            clickable
            @click="onPromoteClick"
          >
            <div class="full-width text-left">Сделать текущим</div>
          </q-item>
          <q-item
            v-close-popup
            class="flex-center"
            clickable
            @click="duplicateReport"
          >
            <div class="full-width text-left">Дублировать</div>
          </q-item>
          <q-item
            v-close-popup
            class="flex-center"
            clickable
            @click="onDeleteClick"
          >
            <div class="full-width text-left">Удалить</div>
          </q-item>
        </q-list>
      </q-menu>
      <q-menu v-else style="width: 150px">
        <q-list>
          <q-item
            v-close-popup
            class="flex-center"
            clickable
            @click="duplicateReport"
          >
            <div class="full-width text-left">Дублировать</div>
          </q-item>
          <q-item
            v-close-popup
            class="flex-center"
            clickable
            @click="onDeleteClick"
          >
            <div class="full-width text-left">Удалить</div>
          </q-item>
        </q-list>
      </q-menu>
    </q-btn>
  </q-td>
</template>

<script setup>
import { store } from "@/store/store";
import { appConfirm } from "@/components/appConfirm/appConfirm";
import { openModalPage } from "@/modalPages/helpers/openModalPage";
import { modalName } from "@/modalPages/helpers/modalName";
import { nanoid } from "nanoid";

const onDeleteClick = async () => {
  const res = await appConfirm("Вы уверены, что хотите удалить отчет?");

  if (res) {
    props.remove(props.report);
  }
};

const duplicateReport = () => {
  openModalPage(modalName.modalRoute, {
    initialReport: props.report,
    onConfirm: (report) => {
      store.allReports.reports.push({
        ...JSON.parse(JSON.stringify(report)),
        key: nanoid(),
      });

      props.promoteToCurrent(
        store.allReports.reports[store.allReports.reports.length - 1]
      );
    },
  });
};

const props = defineProps({
  remove: {
    type: Function,
    default: undefined,
  },
  promoteToCurrent: {
    type: Function,
    default: undefined,
  },
  report: {
    type: Object,
    default: undefined,
  },
});

const onPromoteClick = (event) => {
  event.stopPropagation();

  props.promoteToCurrent(props.report);
};
</script>
