import { createHeaderCell } from "@/helpers/excel/helpers/createHeaderCell";
import { setDivider } from "@/helpers/excel/helpers/setDivider";
import { styleSheet } from "@/helpers/excel/helpers";
import { countReportBalance } from "@/helpers/reports/countReportBalance";

export function setHikeInformation(sheet, hikeInformation) {
  const rowStart = sheet.rowCount + 1;
  let rowIndex = sheet.rowCount + 1;

  createHeaderCell(sheet.getCell(rowIndex, 1), "ID Маршрута");
  sheet.getCell(rowIndex, 2).value = hikeInformation.hikeId;
  rowIndex += 1;

  createHeaderCell(sheet.getCell(rowIndex, 1), "Маршрут");
  sheet.getCell(rowIndex, 2).value = hikeInformation.name;
  rowIndex += 1;

  createHeaderCell(sheet.getCell(rowIndex, 1), "Даты похода");
  sheet.getCell(rowIndex, 2).value = hikeInformation.dates;
  rowIndex += 1;

  const instructorsPayments = hikeInformation.outgoingPayments.find(
    (payment) => payment.label === "Доходы инструктора"
  );

  const instructorsLabel = [
    ...new Set(
      instructorsPayments.payments.map(
        ({ name, instructorId }) => `[${instructorId}] ${name}`
      )
    ),
  ].join(", ");

  createHeaderCell(sheet.getCell(rowIndex, 1), "Инструкторы");
  sheet.getCell(rowIndex, 2).value = instructorsLabel || "-";
  rowIndex += 1;

  createHeaderCell(
    sheet.getCell(rowIndex, 1),
    "Реальное количество участников"
  );
  sheet.getCell(rowIndex, 2).value = hikeInformation.members;
  rowIndex += 1;

  createHeaderCell(sheet.getCell(rowIndex, 1), "Не явившиеся участники");
  sheet.getCell(rowIndex, 2).value = hikeInformation.inactiveMembers;
  styleSheet(sheet, rowStart, 2);

  setDivider(sheet);
  const countRowStart = sheet.rowCount + 1;
  rowIndex = countRowStart;

  createHeaderCell(sheet.getCell(rowIndex, 1), "Итого по походу");

  createHeaderCell(sheet.getCell(rowIndex + 1, 2), "Всего получено");
  createHeaderCell(sheet.getCell(rowIndex + 2, 2), "Всего расходов");
  createHeaderCell(
    sheet.getCell(rowIndex + 3, 2),
    "Всего по прибыли инструктора(ов)"
  );
  createHeaderCell(sheet.getCell(rowIndex + 4, 2), "Итого остаток на руках");
  createHeaderCell(
    sheet.getCell(rowIndex + 5, 2),
    "Итого остаток на руках после передач"
  );

  if (!hikeInformation.balance?.length) return;

  hikeInformation.balance = countReportBalance(hikeInformation);

  for (let i = 0; i < hikeInformation.balance?.length; i++) {
    createHeaderCell(
      sheet.getCell(rowIndex, 3 + i),
      hikeInformation.balance[i].moneyCode
    );
    sheet.getCell(rowIndex + 1, 3 + i).value =
      hikeInformation.balance[i].revenue || 0;
    sheet.getCell(rowIndex + 2, 3 + i).value =
      hikeInformation.balance[i].expenses || 0;
    sheet.getCell(rowIndex + 3, 3 + i).value =
      hikeInformation.balance[i].instructorsRevenue || 0;
    sheet.getCell(rowIndex + 4, 3 + i).value =
      hikeInformation.balance[i].resultWithIncomingPayments || 0;
    sheet.getCell(rowIndex + 5, 3 + i).value =
      hikeInformation.balance[i].finalResult || 0;
  }

  styleSheet(sheet, countRowStart, hikeInformation.balance?.length + 2);
}
