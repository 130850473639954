import { store } from "@/store/store";

export const getReportIndex = (report) => {
  for (let index = 0; index <= store.allReports.reports.length - 1; index++) {
    if (store.allReports.reports[index].key === report.key) {
      return index;
    }
  }
  return -1;
};
