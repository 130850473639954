<template>
  <!--    <app-informer>-->
  <!--      <p>-->
  <!--        <strong>Итог проведения =</strong> общая сумма оплат участников - расходы-->
  <!--        - доходы инструктора, и все это с учетом конвертации-->
  <!--      </p>-->
  <!--      <p>-->
  <!--        <strong>Итого с учетом получений =</strong> все то же, что и выше, только-->
  <!--        еще плюсуем получено от инструкторов/кураторов/офиса-->
  <!--      </p>-->
  <!--      <p>-->
  <!--        <strong>Баланс на руках после передач =</strong> все тоже, что и выше,-->
  <!--        только еще минусуем передано инструкторам/кураторам/офису-->
  <!--      </p>-->
  <!--    </app-informer>-->
  <payments-information
    :payments="
      store.currentReport.outgoingPayments.filter(
        (payment) => payment.label !== 'Доходы инструктора'
      )
    "
    :money-codes="store.currentReport.moneyCodes"
  />
  <div class="q-pb-lg">
    <current-report-table />
  </div>
  <div class="flex justify-center q-pb-lg">
    <app-informer>
      <p>
        Чтобы координатор увидел ваш отчет, синхронизируйте его с гугл диском.
        Координатор сможет найти его у себя в папке
      </p>
      <div>
        Синхронизировать отчеты можно сколько угодно раз, при каждой новой
        синхронизации текущая версия будет заменять предыдущую
      </div>
    </app-informer>
    <q-btn
      no-caps
      color="orange"
      class="full-width"
      icon="cloud_download"
      :loading="state.isReportUploading"
      @click="onUploadReport"
    >
      <div class="q-ml-md">Синхронизировать с гугл диском</div>
    </q-btn>
  </div>
  <div class="flex justify-center q-mb-lg">
    <app-informer>
      <div>
        На всякий случай доступно простое скачивание файлом. Но во избежании
        потерь информации, лучше пользоваться кнопкой выше
      </div>
    </app-informer>
    <q-btn
      no-caps
      class="full-width"
      color="orange"
      icon="sim_card_download"
      @click="onReportDownload"
    >
      <div class="q-ml-md">Скачать отчет файлом на устройство</div>
    </q-btn>
  </div>
</template>

<script setup>
import { store } from "@/store/store";
import PaymentsInformation from "@/components/paymentsInformation/PaymentsInformation";
import CurrentReportTable from "@/pages/currentReport/panels/results/components/currentReportTable/CurrentReportTable";
import { getHikeReport } from "@/helpers/excel/reports/getHikeReport";
import { getHikeReportName } from "@/helpers/reports/getHikeReportName";
import axios from "axios";
import { reactive } from "vue";
import { modalName } from "@/modalPages/helpers/modalName";
import { openModalPage } from "@/modalPages/helpers/openModalPage";
import { getReportDriveInfo } from "@/helpers/reports/getReportDriveInfo";
import AppInformer from "@/components/appInformer/AppInformer.vue";
import { downloadHikeReport } from "@/helpers/downloadHikeReport";
import { isHikeReportValidForProcessing } from "@/helpers/reports/isReportValidForProcessing";
import { appAlert } from "@/components/appAlert/appAlert";

const state = reactive({
  isReportUploading: false,
});

const onReportDownload = async () => {
  const { isValid, reason } = isHikeReportValidForProcessing(
    store.currentReport
  );

  if (!isValid) {
    await appAlert(reason);
    return;
  }

  downloadHikeReport(store.currentReport);
};

const onUploadReport = async () => {
  const { isValid, reason } = isHikeReportValidForProcessing(
    store.currentReport
  );

  if (!isValid) {
    await appAlert(reason);
    return;
  }

  uploadReport();
};

const uploadReport = async () => {
  const targetReport = await getHikeReport(store.currentReport);

  try {
    state.isReportUploading = true;

    const { coordinator, routeId } = await getReportDriveInfo(
      store.currentReport
    );
    const { data } = await axios.post("/api/report", {
      fileName: getHikeReportName(store.currentReport),
      report: btoa(String.fromCharCode.apply(null, targetReport)),
      coordinatorFolder: coordinator,
      routeFolder: `${store.currentReport.name} [${routeId}]`,
    });

    openModalPage(modalName.modalReportLink, {
      reportLink: data?.reportLink,
      name: getHikeReportName(store.currentReport),
    });
  } finally {
    state.isReportUploading = false;
  }
};
</script>

<style scoped>
.item {
  margin-bottom: 15px;
}
</style>
