import ExcelJS from "exceljs";
import {
  setConversions,
  setExpenses,
  setHikeInformation,
  setCommonPayments,
  setPayments,
  setInstructorsRevenue,
} from "@/helpers/excel/sheetDataSetters";
import { createHeaderCell, styleSheet } from "@/helpers/excel/helpers";
import { setDivider } from "@/helpers/excel/helpers/setDivider";
import { store } from "@/store/store";
import { headerCellColor } from "@/helpers/excel/helpers/createHeaderCell";

export const getHikeReport = (reportData) => {
  const workbook = new ExcelJS.Workbook();

  const sheet = workbook.addWorksheet("Общее");

  sheet.getCell(1, 1).value = `v${store.reportsVersion}`;

  setHikeInformation(sheet, reportData);
  setDivider(sheet);
  setPayments(
    sheet,
    reportData.outgoingPayments.filter(
      (payment) => payment.label !== "Доходы инструктора"
    ),
    headerCellColor.negative
  );
  styleSheet(sheet);

  const incomingPaymentsSheet = workbook.addWorksheet("Получено");

  setCommonPayments(incomingPaymentsSheet, reportData.moneySums);
  setPayments(
    incomingPaymentsSheet,
    [reportData.incomingPayments[0]],
    null,
    false
  );
  styleSheet(incomingPaymentsSheet, 1, 6);
  setDivider(incomingPaymentsSheet);

  setPayments(
    incomingPaymentsSheet,
    reportData.incomingPayments.slice(1),
    headerCellColor.positive
  );
  styleSheet(incomingPaymentsSheet);

  const expensesSheet = workbook.addWorksheet("Расходы");

  setExpenses(expensesSheet, reportData.expenses);
  setDivider(expensesSheet);
  // setGroupedExpenses(expensesSheet, groupExpenses(reportData.expenses));
  // setPaymentsResult(expensesSheet, [
  //   ...groupExpenses(reportData.expenses).filter(
  //     ({ isUncountable }) => !isUncountable
  //   ),
  // ]);
  setConversions(expensesSheet, reportData.conversions);
  styleSheet(expensesSheet);

  const instructorRevenue = workbook.addWorksheet("Доходы инструктора");
  setInstructorsRevenue(
    instructorRevenue,
    reportData.outgoingPayments,
    headerCellColor.negative
  );

  const checkListWorkSheet = workbook.addWorksheet("Чеклист");

  reportData.checklist.forEach((checkListItem, index) => {
    createHeaderCell(
      checkListWorkSheet.getCell(index + 1, 1),
      checkListItem.header
    );
    checkListWorkSheet.getCell(index + 1, 2).value = checkListItem.result;
    checkListWorkSheet.getCell(index + 1, 3).value = checkListItem.comment;
  });

  checkListWorkSheet.spliceColumns(4, 2);
  checkListWorkSheet.spliceRows(7, 2);

  styleSheet(checkListWorkSheet, 1, 3);

  return workbook.xlsx.writeBuffer();
};
