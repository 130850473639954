import {
  createHeaderCell,
  headerCellColor,
} from "@/helpers/excel/helpers/createHeaderCell";
import { setPayments } from "@/helpers/excel/sheetDataSetters/setPayments";
import { styleSheet } from "@/helpers/excel/helpers";
import { setPaymentsResult } from "@/helpers/excel/sheetDataSetters/setPaymentsResult";
import { getTotalInstructorsRevenueForMoneyCode } from "@/helpers/reports/getTotalInstructorsRevenueForMoneyCode";
import { paymentTableType } from "@/helpers/paymentTableType";

export const setInstructorsPaymentsResult = (
  sheet,
  payments,
  style = true,
  header,
  color
) => {
  const rowStart = sheet.rowCount + 1;
  let rowIndex = rowStart;

  if (header) {
    createHeaderCell(sheet.getCell(rowIndex, 1), header, color);
  }
  createHeaderCell(sheet.getCell(rowIndex, 2), "Итого");
  rowIndex += 1;

  sheet.getCell(rowIndex, 3).value = getTotalInstructorsRevenueForMoneyCode(
    payments,
    "RUB"
  );
  sheet.getCell(rowIndex, 4).value = "RUB";

  if (style) {
    styleSheet(sheet, rowStart, 6);
  }
};

const expandPayments = (payments) => {
  const result = [];

  for (const payment of payments) {
    result.push(
      {
        ...payment,
        comment: "Сумма основной части",
        sum: payment.sum,
      },
      {
        ...payment,
        comment: "Сумма бонусной части",
        sum: payment.bonusSum || 0,
      },
      {
        ...payment,
        comment: "Бонус за покупку раскладки",
        sum: payment.soldItemsSum || 0,
      }
    );
  }

  return result;
};

export function setInstructorsIncomePayments(
  sheet,
  outgoingPayments,
  color,
  style = true
) {
  const rowStart = sheet.rowCount + 1;
  let rowIndex = rowStart;

  for (let payment of outgoingPayments) {
    const { label, payments } = payment;

    createHeaderCell(sheet.getCell(rowIndex, 1), label, color);

    if (
      payment.type !== paymentTableType.members &&
      payment.type !== paymentTableType.office
    ) {
      createHeaderCell(sheet.getCell(rowIndex, 2), "[id] ФИО");
    }

    if (payment.type === paymentTableType.members) {
      createHeaderCell(sheet.getCell(rowIndex, 2), "Участник");
    }

    createHeaderCell(sheet.getCell(rowIndex, 3), "Сумма");
    createHeaderCell(sheet.getCell(rowIndex, 4), "Валюта");
    createHeaderCell(
      sheet.getCell(rowIndex, 5),
      "Комментарий",
      undefined,
      headerCellColor.hint
    );
    createHeaderCell(
      sheet.getCell(rowIndex, 6),
      "Дата",
      undefined,
      headerCellColor.hint
    );
    rowIndex += 1;

    for (let i = 0; i < payments?.length; i++) {
      if (
        payment.type !== paymentTableType.office &&
        payment.type !== paymentTableType.members
      ) {
        sheet.getCell(rowIndex, 2).value =
          `[${payments[i].instructorId}] ${payments[i].name}` || "-";
      }

      if (payment.type === paymentTableType.members) {
        sheet.getCell(rowIndex, 2).value = payments[i].name;
      }

      sheet.getCell(rowIndex, 3).value =
        Number(payments[i].sum || 0) + Number(payments[i].bonusSum || 0) + Number(payments[i].soldItemsSum || 0);
      sheet.getCell(rowIndex, 4).value = payments[i].moneyCode;
      sheet.getCell(rowIndex, 5).value = payments[i].comment || "-";
      sheet.getCell(rowIndex, 6).value = payments[i].date || "-";
      rowIndex += 1;
    }
  }

  if (style) {
    styleSheet(sheet, rowStart, 6);
  }
}

export function setInstructorsRevenue(sheet, outgoingPayments) {
  const startRow = sheet.rowCount + 1;

  const payments = outgoingPayments.filter(
    (payment) => payment.label === "Доходы инструктора"
  );

  setPayments(
    sheet,
    [{ ...payments[0], payments: expandPayments(payments[0].payments) }],
    headerCellColor.negative,
    false
  );

  let rowIndex = sheet.rowCount + 1;
  const savedDescIndex = rowIndex;

  setInstructorsIncomePayments(
    sheet,
    payments,
    headerCellColor.negative,
    false
  );
  createHeaderCell(
    sheet.getCell(savedDescIndex, 1),
    "Сумма доходов инструктора",
    headerCellColor.negative
  );

  // createHeaderCell(sheet.getCell(rowIndex, 2), "Категория");
  // createHeaderCell(sheet.getCell(rowIndex, 3), "Сумма");
  // createHeaderCell(sheet.getCell(rowIndex, 4), "Валюта");
  // createHeaderCell(sheet.getCell(rowIndex, 5), "Кол-во");
  //
  // for (let payment of groupPayments(payments[0].payments)) {
  //   rowIndex += 1;
  //   sheet.getCell(
  //     rowIndex,
  //     2
  //   ).value = `[${payment.instructorId}] ${payment.name}`;
  //   sheet.getCell(rowIndex, 3).value = payment.isUncountable
  //     ? "-"
  //     : payment.sum;
  //   sheet.getCell(rowIndex, 4).value = payment.isUncountable
  //     ? "-"
  //     : payment.moneyCode;
  //   sheet.getCell(rowIndex, 5).value = payment.count || 1;
  // }

  setInstructorsPaymentsResult(
    sheet,
    payments[0].payments,
    false,
    "Итого по доходам инструктора",
    headerCellColor.negative
  );

  styleSheet(sheet, startRow, 6);
}
