<template>
  <text-header>{{ props.header || "Изменить общие данные" }}</text-header>
  <hike-main-info
    :hike="data.report"
    :update-hike-name="updateHikeName"
    :update-hike-information="updateHikeInformation"
    :is-date-error="isDateError"
    :is-name-error="isNameError"
  />
  <numeric-input
    :value="data.report.members"
    class="item"
    outlined
    label="Реальное количество участников"
    type="number"
    :error="isMembersError"
    :on-value-change="onMembersChange"
  />
  <q-input
    :model-value="data.report.inactiveMembers"
    class="item"
    outlined
    label="Не явившиеся участники (ФИО)"
    @update:model-value="onInactiveMembersInputChange"
  />
  <text-header :description="unitsDescriptions.currentReportRates.description">
    {{ unitsDescriptions.currentReportRates.header }}
  </text-header>
  <money-code-select
    :model-value="data.report?.moneyCodes"
    :on-code-select="onCodeSelect"
    :on-code-remove="onCodeRemove"
  />
  <!--  <notification-data-saved v-if="isNotificationVisible" />-->
  <div class="confirmation">
    <form-confirmation
      :dismiss-handler="onDismiss"
      :confirm-handler="onConfirm"
      :on-disabled-button-click="onDisabledSubmit"
      :is-confirm-button-disabled="isConfirmButtonDisabled"
    />
  </div>
</template>

<script setup>
import { store } from "@/store/store";
import MoneyCodeSelect from "@/components/moneyCodeSelect/MoneyCodeSelect";
import HikeMainInfo from "@/components/hikeMainInfo/HikeMainInfo";
import { hasPaymentsForMoneyCode } from "@/helpers/reports/hasPaymentsForMoneyCode";
import { toCamelCase } from "@/helpers/toCamelCase";
import { appAlert } from "@/components/appAlert/appAlert";
import TextHeader from "@/components/textHeader/TextHeader.vue";
import { unitsDescriptions } from "@/helpers/unitsDescriptions";
import FormConfirmation from "@/components/fromConfirmation/FormConfirmation.vue";
import { computed, onMounted, reactive, watch } from "vue";
import { getEmptyReport } from "@/helpers/reports/getEmptyReport";
import { closeModalPage } from "@/modalPages/helpers/closeModalPage";
import * as lodash from "lodash";
import NumericInput from "@/components/numericInput/NumericInput.vue";
import { isNotExists } from "@/helpers/isNotExists";

const props = store.modalPages.props;

const data = reactive({
  isError: false,
  report: props.report ? lodash.clone(props.report) : getEmptyReport(),
});

const updateHikeName = (name) => {
  data.report.name = name;
};

const onMembersChange = (members) => {
  data.report.members = Math.abs(members);
};

function updateHikeInformation(
  dates,
  hikeId,
  routeId,
  coordinator,
  instructor
) {
  data.report.dates = !dates ? [] : dates;
  data.report.hikeId = hikeId;
  data.report.routeId = routeId;
  data.report.coordinator = coordinator;
  data.report.instructor = instructor;
}

const onCodeRemove = (index) => {
  if (hasPaymentsForMoneyCode(data.report.moneyCodes[index])) {
    appAlert("Нельзя удалить использующуюся валюту");
  } else {
    data.report.moneyCodes.splice(index, 1);
  }
};

const onCodeSelect = (code) => {
  if (data.report?.moneyCodes?.includes(code)) {
    return;
  }
  data.report.moneyCodes.push(code);
};

const onInactiveMembersInputChange = (inactiveMembers) => {
  data.report.inactiveMembers = toCamelCase(inactiveMembers);
};

const onConfirm = () => {
  if (isConfirmButtonDisabled.value) {
    return;
  }

  props.saveData(data.report);

  closeModalPage();
};

const isConfirmButtonDisabled = computed(() => {
  return (
    isNotExists(data.report.hikeId) ||
    isNotExists(data.report.dates) ||
    isNotExists(data.report.name) ||
    isRouteExists.value
  );
});

const isRouteExists = computed(() => {
  const isExist = [...store.allReports.reports].find(
    (report) => Number(report?.hikeId) === Number(data.report?.hikeId)
  );

  return !!isExist;
});

const onDismiss = () => {
  closeModalPage();
};

onMounted(() => {
  data.report.members = data.report.members || 0;
});

watch(data.report?.moneyCodes, () => {
  data.report?.moneyCodes.forEach((moneyCode) => {
    if (!!data.report.moneySums[moneyCode]) {
      return;
    }

    data.report.moneySums[moneyCode] = 0;
  });
});

const onDisabledSubmit = () => {
  data.isError = true;
};

const isDateError = computed(() => {
  return (!data.report?.dates?.length && data.isError) || isRouteExists.value;
});

const isNameError = computed(() => isNotExists(data.report?.name));
const isMembersError = computed(
  () => data.report.members === undefined && data.isError
);
</script>

<style scoped>
.item {
  padding: 0 0 5px 0;
}

.confirmation {
  margin-top: 30px;
}
</style>
