<template>
  <text-header> {{ props.header || "Добавить отчет по походу" }}</text-header>
  <hike-main-info
    :hike="data"
    :update-hike-name="onHikeNameUpdate"
    :update-hike-information="onHikeInformationUpdate"
    :is-name-error="!data?.name && state.isError"
    :is-date-error="(!data?.dates && state.isError) || isRouteExists"
  />
  <form-confirmation
    :dismiss-handler="closeModalPage"
    :confirm-handler="onConfirm"
    :is-confirm-button-disabled="isConfirmButtonDisabled"
    :on-disabled-button-click="activateError"
  />
</template>

<script setup>
import { store } from "@/store/store";
import { reactive, computed, onMounted } from "vue";
import { closeModalPage } from "@/modalPages/helpers/closeModalPage";
import FormConfirmation from "@/components/fromConfirmation/FormConfirmation";
import HikeMainInfo from "@/components/hikeMainInfo/HikeMainInfo";
import { getEmptyReport } from "@/helpers/reports/getEmptyReport";
import TextHeader from "@/components/textHeader/TextHeader";

const props = store.modalPages.props;
const data = reactive({
  name: "",
  dates: "",
  hikeId: "",
});

onMounted(() => {
  if (props.initialReport) {
    data.name = props.initialReport.name;
    data.dates = props.initialReport.dates;
    data.hikeId = props.initialReport.hikeId;
  }
});

const isRouteExists = computed(() => {
  const isExist = [store.currentReport, ...store.allReports.reports].find(
    (report) => Number(report?.hikeId) === Number(data.hikeId)
  );

  return !!isExist;
});

const isConfirmButtonDisabled = computed(() => {
  return !(data.name && data.dates && !isRouteExists.value);
});

const onHikeInformationUpdate = (
  dates,
  hikeId,
  routeId,
  coordinator,
  instructor
) => {
  data.dates = dates;
  data.hikeId = hikeId;
  data.routeId = routeId;
  data.coordinator = coordinator;
  data.instructor = instructor;
};

const onHikeNameUpdate = (name) => {
  data.name = name;
};

const state = reactive({ isError: false });
const activateError = () => {
  if (isConfirmButtonDisabled.value) {
    state.isError = true;
  }
};

const onConfirm = () => {
  const report = {
    ...JSON.parse(
      JSON.stringify(
        props.initialReport ? props.initialReport : getEmptyReport()
      )
    ),
    name: data.name,
    dates: data.dates,
    hikeId: data.hikeId,
    instructor: data.instructor,
  };
  props.onConfirm(report);
  closeModalPage();
};
</script>

<style scoped></style>
