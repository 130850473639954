export const request = async (url, requestHeaders) => {
  let username = process.env.VUE_APP_API_USERNAME;
  let password = process.env.VUE_APP_API_PASSWORD;

  let headers = new Headers();

  headers.append(
    "Authorization",
    "Basic " + window.btoa(username + ":" + password)
  );

  if (requestHeaders) {
    Object.entries(requestHeaders).forEach(([key, value]) => {
      headers.append(key, value);
    });
  }

  return fetch(url, {
    method: "GET",
    headers,
    cache: "no-cache",
  }).then((response) => response.json());
};
