export const getExpensesSumForMoneyCode = (currentReport, code) => {
  let sum = 0;

  for (const expense of currentReport.expenses) {
    if (expense.moneyCode === code) {
      sum += Number(expense.sum);
    }
  }

  return sum;
};
