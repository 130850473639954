<template>
  <div class="main">
    <p>
      <slot></slot>
      <br>
      <label class="hint" v-if="!!props.hint">
        {{ props.hint }}
      </label>
    </p>
    <question-btn v-if="!!props.description" :on-click="onHelpClick" />
  </div>
</template>

<script setup>
import { appAlert } from "@/components/appAlert/appAlert";
import QuestionBtn from "@/components/questionBtn/QuestionBtn.vue";

const props = defineProps({
  description: {
    type: String,
    default: () => "",
  },
  hint: {
    type: String,
    default: () => "",
  },
});

const onHelpClick = () => {
  appAlert(props.description || "Описание");
};
</script>

<style scoped>
.main {
  display: flex;
  justify-content: center;
  align-items: center;
}

p {
  margin: 1em 5px;
  font-weight: 600;
  font-size: 16px;
  line-height: 1;
  text-align: center;
}

.hint {
  font-weight: 600;
  font-size: 12px;
  line-height: 1;
  text-align: center;
}
</style>
