<template>
  <default-table
    class="balance-table"
    :columns="appBalanceColumns"
    :rows="tableRows"
  >
    <template #body-cell-date="{ props }">
      <q-td v-if="props.pageIndex === tableRows.length - 1 || props.value">
        <div class="cell">{{ props.value }}</div>
      </q-td>
      <q-td v-else-if="!props.value">
        <div class="cell">Укажите дату конвертации</div>
      </q-td>
    </template>
    <template #body-cell-edit-time="{ props }">
      <cell-edit-time
        v-if="
          props.pageIndex !== tableRows.length - 1 &&
          !isRubles(props.row.moneyCode)
        "
        :money-code="props.row.moneyCode"
        :edit="tableProps.edit"
      />
      <q-td v-else> </q-td>
    </template>
    <template #header-cell-date="{ props }">
      <q-th style="min-width: 60px">
        {{ props.col.label }}
      </q-th>
    </template>
    <template #body-cell-balance-converted-sum="{ props }">
      <cell-filled
        v-if="
          props.pageIndex === tableRows.length - 1 && reactiveData.hasConnection
        "
        :value="props.value"
      />
      <q-td v-else-if="store.allReports?.isBalanceLoading">
        <div class="cell">
          <q-spinner color="orange" size="24px" />
        </div>
      </q-td>
      <q-td v-else-if="!props.row.date">
        <div class="cell">Укажите дату конвертации</div>
      </q-td>
      <q-td
        v-else-if="
          !props.row?.isConvertable &&
          props.row?.moneyCode !== 'RUB' &&
          props.row?.originalBalance?.sum !== 0
        "
      >
        <div class="cell">Неизвестная валюта</div>
      </q-td>
      <cell-filled
        v-else-if="
          !props.row?.isConvertable &&
          props.row?.moneyCode !== 'RUB' &&
          props.row?.originalBalance?.sum === 0
        "
        :value="props.row?.sum"
      />
      <cell-filled
        v-else-if="
          (reactiveData.hasConnection && props.value) ||
          (isRubles(props.value) && props.pageIndex !== tableRows.length - 1)
        "
        :value="props.value"
      />
      <q-td v-else-if="!reactiveData.hasConnection">
        <div class="cell">Ожидание интернета</div>
      </q-td>
      <q-td v-else>
        <div class="cell">-</div>
      </q-td>
    </template>
    <template #body-cell-balance-sum="{ props }">
      <q-td
        v-if="props.pageIndex === tableRows.length - 1"
        style="background: rgb(128, 128, 128); color: white"
      >
        <div class="cell">
          {{ props.value }}
        </div>
      </q-td>
      <cell-filled v-else :value="props.value" />
    </template>
  </default-table>
</template>

<script setup>
import DefaultTable from "@/components/table/DefaultTable";
import CellEditTime from "@/pages/allReports/panels/balance/components/balanceTable/cellEditTime/CellEditTime";
import { appBalanceColumns } from "@/pages/allReports/panels/balance/components/balanceTable/appBalanceColumns";
import CellFilled from "@/components/table/cellFilled/cellFilled";
import { store } from "@/store/store";
import { computed, onMounted, onUnmounted, reactive } from "vue";
import { hasInternetConnection } from "@/helpers/hasInternetConnection";
import { countAllReportsFinalConvertedSum } from "@/helpers/reports/countAllReportsFinalConvertedSum";
import { formatNumber } from "@/helpers/formatNumber";

const isRubles = (label) => {
  return label.includes("RUB");
};

const tableProps = defineProps({
  columns: {
    type: Array,
    default: () => [],
  },
  rows: {
    type: Array,
    default: () => [],
  },
  edit: {
    type: Function,
    default: undefined,
  },
});

const reactiveData = reactive({
  hasConnection: true,
});

let intervalId;

onMounted(async () => {
  reactiveData.hasConnection = await hasInternetConnection();

  intervalId = setInterval(async () => {
    reactiveData.hasConnection = await hasInternetConnection();
  }, 2000);
});

onUnmounted(() => {
  clearInterval(intervalId);
});

const tableRows = computed(() => {
  const convertedResult = countAllReportsFinalConvertedSum(
    store.allReports.balance
  );

  return [
    ...tableProps.rows,
    {
      moneyCode: "RUB",
      sum: "Итого:",
      convertedSum:
        convertedResult === "-" ? "-" : `${formatNumber(convertedResult)} RUB`,
    },
  ];
});
</script>

<style>
.balance-table td:nth-child(1),
.balance-table td:nth-child(2),
.balance-table td:nth-child(3) {
  width: 33%;
}

.balance-table td:nth-child(4) {
  width: 42px;
}
</style>
