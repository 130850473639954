export const checklistColumns = [
  {
    align: "left",
    name: "comment",
    field: "comment",
    label: "Комментарий",
    sortable: true,
  },
  {
    align: "left",
    name: "edit",
    field: "edit",
  },
];
