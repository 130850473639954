export const unitsDescriptions = {
  currentReportRates: {
    header: "Валюты этого отчета",
    description: "Выбери валюты используемые в отчете.",
  },
  clientsSum: {
    header: "Общая сумма оплат участников",
    description: "Укажи сколько всего сдали участники.",
  },
  clientsSumWithExceptions: {
    header: "Укажи сколько всего сдали участники",
    description:
      "Отдельно укажи каждого участника, который сдал больше или меньше чем указано в списке от координатора.",
  },
  fromInstructors: {
    header: "Получено от инструкторов",
    description:
      "Укажи сколько осталось с предыдущего похода или сколько тебе передали.",
  },
  fromTutors: {
    header: "Получено от кураторов",
    description:
      "Укажи сколько осталось с предыдущего похода или сколько тебе передали.",
  },
  fromOffice: {
    header: "Получено из офиса",
    description: "Укажи сколько получил в офисе.",
  },
  instructorRevenue: {
    header: "Доходы инструктора",
    description:
      "Укажи размер своей прибыли, заложенной в предварительный отчет.",
  },
  toInstructors: {
    header: "Передано инструкторам",
    description:
      "Укажи сколько средств остается на будущие походы или передаются другим инструкторам.",
  },
  toTutors: {
    header: "Передано кураторам",
    description: "Укажи сколько средств передается куратору.",
  },
  toOffice: {
    header: "Передано в офис",
    description: "Укажи сколько вернул/вернешь в офис.",
  },
  generalExpenses: {
    header: "Общие расходы",
    description: "Укажи все расходы вне походов.",
  },
};
