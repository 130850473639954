import { formatNumber } from "@/helpers/formatNumber";
import { countResultForMoneyCode } from "@/helpers/reports/countResultForMoneyCode";
import { countResultForMoneyCodeWithIncomingPayments } from "@/helpers/reports/countResultForMoneyCodeWithIncomingPayments";
import { countFinalResult } from "@/helpers/reports/countFinalResult";
import { getExpensesSumForMoneyCode } from "@/helpers/reports/getExpensesSumForMoneyCode";
import { getTotalInstructorsRevenueForMoneyCode } from "@/helpers/reports/getTotalInstructorsRevenueForMoneyCode";
import { countRevenueForMoneyCode } from "@/helpers/reports/countRevenueForMoneyCode";

export const countReportBalance = (report) => {
  return [
    ...report.moneyCodes.map((code) => {
      return {
        moneyCode: code,
        revenue: formatNumber(countRevenueForMoneyCode(report, code) || 0),
        expenses: formatNumber(getExpensesSumForMoneyCode(report, code) || 0),
        instructorsRevenue: formatNumber(
          getTotalInstructorsRevenueForMoneyCode(report, code) || 0
        ),
        resultWithIncomingPayments: formatNumber(
          countResultForMoneyCodeWithIncomingPayments(report, code) || 0
        ),
        finalResult: formatNumber(countFinalResult(report, code) || 0),
      };
    }),
  ].sort((b, a) => {
    if (a.moneyCode === "RUB") {
      return 1;
    } else if (b.moneyCode === "RUB") {
      return -1;
    } else if (+b.result !== 0) {
      return -1;
    } else if (+a.result !== 0) {
      return 1;
    }

    return 0;
  });
};
