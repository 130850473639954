import { fillEmptyCells } from "@/helpers/excel/helpers/fillEmptyCells";
import { castWorksheetStringsToNumbers } from "@/helpers/excel/helpers/castWorksheetStringsToNumbers";
import { setColumnsWidth } from "@/helpers/excel/helpers/setColumnsWidth";

export function styleSheet(sheet, startRow, columns) {
  const { rowCount } = sheet;
  fillEmptyCells(sheet, startRow, columns);
  setColumnsWidth(sheet, 20);
  const minRow = startRow ? startRow : 1;
  const maxColumn = columns ? columns : 0;

  for (let rowIndex = minRow; rowIndex <= rowCount; rowIndex++) {
    for (let columnIndex = 1; columnIndex <= maxColumn; columnIndex++) {
      sheet.getCell(rowIndex, columnIndex).font = {
        ...sheet.getCell(rowIndex, columnIndex).font,
        name: "Arial",
        size: 10,
      };

      sheet.getCell(rowIndex, columnIndex).alignment = {
        horizontal: "center",
        vertical: "middle",
        wrapText: true,
      };

      if (rowIndex === startRow) {
        sheet.getCell(rowIndex, columnIndex).border = {
          ...sheet.getCell(rowIndex, columnIndex).border,
          top: { style: "medium", color: { argb: "000000" } },
        };
      }

      if (columnIndex === 1) {
        sheet.getCell(rowIndex, columnIndex).border = {
          ...sheet.getCell(rowIndex, columnIndex).border,
          left: { style: "medium", color: { argb: "000000" } },
        };
      }

      if (columnIndex === maxColumn) {
        sheet.getCell(rowIndex, columnIndex).border = {
          ...sheet.getCell(rowIndex, columnIndex).border,
          right: { style: "medium", color: { argb: "000000" } },
        };
      }

      if (rowIndex === rowCount) {
        sheet.getCell(rowIndex, columnIndex).border = {
          ...sheet.getCell(rowIndex, columnIndex).border,
          bottom: { style: "medium", color: { argb: "000000" } },
        };
      }
    }
  }

  castWorksheetStringsToNumbers(sheet);
}
