<template>
  <default-table
    hide-header
    :columns="moneyCodesTableColumns"
    :rows="tableProps.rows"
  >
    <template #body-cell-moneyCode="{ props }">
      <q-td style="width: 92px; min-width: 92px">
        <div class="cell">
          {{ props.value }}
        </div>
      </q-td>
    </template>
    <template #body-cell-sum="{ props }">
      <q-td>
        <div class="cell">
          {{ formatNumber(props.value) }}
        </div>
      </q-td>
    </template>
    <template #body-cell-edit="{ props }">
      <q-td style="width: 42px; min-width: 42px">
        <q-btn
          :disable="props.isDisabled"
          round
          flat
          color="grey"
          icon="more_horiz"
        >
          <q-menu style="width: 130px">
            <q-list style="min-width: 100px">
              <q-item
                v-close-popup
                clickable
                class="flex-center"
                @click="() => onEditClick(props.row.moneyCode)"
              >
                <div class="full-width text-lefts">Изменить</div>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn>
      </q-td>
    </template>
  </default-table>
</template>

<script setup>
import DefaultTable from "@/components/table/DefaultTable";
import { moneyCodesTableColumns } from "@/pages/currentReport/panels/payments/components/moneyCodesTableColumns";
import { formatNumber } from "@/helpers/formatNumber";
import { store } from "@/store/store";
import { openModalPage } from "@/modalPages/helpers/openModalPage";
import { modalName } from "@/modalPages/helpers/modalName";

const tableProps = defineProps({
  rows: {
    type: Array,
    default: () => [],
  },
});

const updateSum = (value, code) => {
  store.currentReport.moneySums[code] = Math.abs(value);
};

const onEditClick = (moneyCode) => {
  openModalPage(modalName.modalMoneyCode, {
    moneyCode,
    saveData: (sum) => {
      updateSum(sum, moneyCode);
    },
    sum: store.currentReport.moneySums[moneyCode],
    header: !store.currentReport.moneySums[moneyCode]
      ? "Сумма оплат участников"
      : "Редактировать сумму оплат участников",
  });
};
</script>
