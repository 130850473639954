import { cachedRequestRoutes } from "@/api/cachedRequests";

export const getReportDriveInfo = async (report) => {
  if (report.coordinator && report.routeId) {
    return {
      routeId: report.routeId,
      coordinator: report.coordinator,
    };
  }

  const routes = await cachedRequestRoutes();

  const result = {
    routeId: "",
    coordinator: "",
  };

  for (const route of routes) {
    const targetReport = route.hikes.find(({ id }) => {
      return Number(report.hikeId) === Number(id);
    });

    if (targetReport) {
      result.coordinator = targetReport?.coordinator;
      result.routeId = route?.id;
      break;
    }
  }

  return result;
};
