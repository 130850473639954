import script from "./BalanceTable.vue?vue&type=script&setup=true&lang=js"
export * from "./BalanceTable.vue?vue&type=script&setup=true&lang=js"

import "./BalanceTable.vue?vue&type=style&index=0&id=74bf4b56&lang=css"

const __exports__ = script;

export default __exports__
import QTd from 'quasar/src/components/table/QTd.js';
import QTh from 'quasar/src/components/table/QTh.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import qInstall from "../../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTd,QTh,QSpinner});
