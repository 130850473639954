import {
  createHeaderCell,
  headerCellColor,
} from "@/helpers/excel/helpers/createHeaderCell";
import { paymentTableType } from "@/helpers/paymentTableType";
import { styleSheet } from "@/helpers/excel/helpers";

export function setPayments(sheet, outgoingPayments, color, style = true) {
  const rowStart = sheet.rowCount + 1;
  let rowIndex = rowStart;

  for (let payment of outgoingPayments) {
    const { label, payments } = payment;

    createHeaderCell(sheet.getCell(rowIndex, 1), label, color);

    if (
      payment.type !== paymentTableType.members &&
      payment.type !== paymentTableType.office
    ) {
      createHeaderCell(sheet.getCell(rowIndex, 2), "[id] ФИО");
    }

    if (payment.type === paymentTableType.members) {
      createHeaderCell(sheet.getCell(rowIndex, 2), "Участник");
    }

    createHeaderCell(sheet.getCell(rowIndex, 3), "Сумма");
    createHeaderCell(sheet.getCell(rowIndex, 4), "Валюта");
    createHeaderCell(
      sheet.getCell(rowIndex, 5),
      "Комментарий",
      undefined,
      headerCellColor.hint
    );
    createHeaderCell(
      sheet.getCell(rowIndex, 6),
      "Дата",
      undefined,
      headerCellColor.hint
    );
    rowIndex += 1;

    for (let i = 0; i < payments?.length; i++) {
      if (
        payment.type !== paymentTableType.office &&
        payment.type !== paymentTableType.members
      ) {
        sheet.getCell(rowIndex, 2).value =
          `[${payments[i].instructorId}] ${payments[i].name}` || "-";
      }

      if (payment.type === paymentTableType.members) {
        sheet.getCell(rowIndex, 2).value = payments[i].name;
      }

      sheet.getCell(rowIndex, 3).value = payments[i].sum;
      sheet.getCell(rowIndex, 4).value = payments[i].moneyCode;
      sheet.getCell(rowIndex, 5).value = payments[i].comment || "-";
      sheet.getCell(rowIndex, 6).value = payments[i].date || "-";
      rowIndex += 1;
    }
  }

  if (style) {
    styleSheet(sheet, rowStart, 6);
  }
}
