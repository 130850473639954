export const modalName = {
  modalPayment: 1,
  modalExpense: 2,
  modalConversion: 3,
  modalTime: 4,
  modalRoute: 5,
  modalComment: 6,
  modalCurrentReport: 7,
  modalMoneyCode: 8,
  modalReportLink: 9,
  modalLoadReport: 10,
  modalTableView: 11,
  modalInstructorsIncome: 12,
};
