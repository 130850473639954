import { getItem, setItem } from "@/helpers/localStorage";

export const cachedRequest = (requestFunction, key) => {
  return async (...args) => {
    const $key = key + args?.join("");
    const cachedData = getItem($key);

    if (cachedData) {
      try {
        const result = await Promise.race([
          requestFunction(...args),
          new Promise((resolve) => setTimeout(() => resolve(cachedData), 7000)),
        ]);

        if (result !== cachedData) {
          setItem($key, result);
        }
        return result;

      } catch (error) {
        return cachedData;
      }
    }

    try {
      const result = await requestFunction.apply(this, args);
      setItem($key, result);
      return result;
    } catch (error) {
      return undefined;
    }
  };
};
