<template>
  <section v-if="!!instructorsIncome">
    <text-header :description="instructorsIncome.description || '-'">{{
      instructorsIncome.label
    }}</text-header>
    <payments-table
      :edit="(index) => editInstructorIncome(instructorsIncome, index)"
      :remove="(index) => removeInstructorIncome(instructorsIncome, index)"
      :columns="paymentsColumns"
      :rows="getInstructorIncomeRows()"
      :header="instructorsIncome.label"
      :row-names="instructorRowNames"
    />
    <button-add :handler="addInstructorIncome" />
  </section>
</template>

<script setup>
import ButtonAdd from "@/components/buttonAdd/ButtonAdd";
import { openModalPage } from "@/modalPages/helpers/openModalPage";
import { modalName } from "@/modalPages/helpers/modalName";
import { store } from "@/store/store";
import { computed } from "vue";
import { formatNumber } from "@/helpers/formatNumber";
import TextHeader from "@/components/textHeader/TextHeader.vue";
import PaymentsTable from "@/components/paymentsTable/PaymentsTable.vue";
import { paymentsColumns } from "@/components/paymentsTable/paymentsColumns";

const edit = (index) => {
  openModalPage(modalName.modalExpense, {
    saveData: (expense) => (store.currentReport.expenses[index] = expense),
    expense: store.currentReport.expenses[index],
    moneyCodes: ["RUB"],
    header: "Редактировать доход инструктора",
  });
};

const instructorsIncome = computed(() => {
  return store.currentReport.outgoingPayments.find(
    (payment) => payment.label === "Доходы инструктора"
  );
});

const addInstructorIncome = () => {
  openModalPage(modalName.modalInstructorsIncome, {
    type: instructorsIncome.value.type,
    saveData: (_payment) => instructorsIncome.value.payments.push(_payment),
    moneyCodes: ["RUB"],
    header: "Добавить доход инструктора",
  });
};

const getInstructorIncomeRows = () => {
  const countSum = (payment) => {
    return (
      Number(payment.sum) +
      Number(payment.bonusSum || 0) +
      Number(payment.soldItemsSum || 0)
    );
  };

  if (!instructorsIncome.value.payments) {
    return [];
  }

  return instructorsIncome.value.payments.map((targetPayment) => ({
    ...targetPayment,
    sum: `${formatNumber(countSum(targetPayment))} ${targetPayment.moneyCode}`,
    income: `${formatNumber(targetPayment.sum)} ${targetPayment.moneyCode}`,
    bonusSum: `${formatNumber(targetPayment.bonusSum || 0)} ${
      targetPayment.moneyCode
    }`,
    soldItemsSum: `${formatNumber(targetPayment.soldItemsSum || 0)} ${
      targetPayment.moneyCode
    }`,
  }));
};

const removeInstructorIncome = (payment, index) => {
  payment.payments.splice(index, 1);
};

const editInstructorIncome = (payment, index) => {
  openModalPage(modalName.modalInstructorsIncome, {
    saveData: (newPayment) => {
      payment.payments[index] = newPayment;
    },
    payment: payment.payments[index],
    moneyCodes: ["RUB"],
    type: payment.type,
    header: "Реадактировать доход инструктора",
  });
};

const instructorRowNames = {
  name: "ФИО",
  income: "Сумма основной части дохода",
  bonusSum: "Сумма бонусной части дохода (не обязательно)",
  soldItemsSum: "Бонус за покупку раскладки (не обязательно)",
  comment: "Комментарий",
  date: "Дата",
};
</script>

<style scoped></style>
