export const formatNumber = (number) => {
  if (Number(number).toString().includes(".")) {
    return Number(number)
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  return Number(number)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};
