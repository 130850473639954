import {
  createHeaderCell,
  headerCellColor,
} from "@/helpers/excel/helpers/createHeaderCell";
import { styleSheet } from "@/helpers/excel/helpers";
import { setGroupedExpenses } from "@/helpers/excel/sheetDataSetters/setGroupedExpenses";
import { setPaymentsResult } from "@/helpers/excel/sheetDataSetters/setPaymentsResult";

export function setExpenses(
  sheet,
  expenses,
  group = true,
  title,
  titleColor,
  resultTitle
) {
  const rowStart = sheet.rowCount + 1;
  let rowIndex = rowStart;

  createHeaderCell(sheet.getCell(rowIndex, 1), title || "Расходы", titleColor);

  createHeaderCell(sheet.getCell(rowIndex, 2), "Категория");
  createHeaderCell(sheet.getCell(rowIndex, 3), "Сумма");
  createHeaderCell(sheet.getCell(rowIndex, 4), "Валюта");
  createHeaderCell(
    sheet.getCell(rowIndex, 5),
    "Расчет расхода",
    undefined,
    headerCellColor.hint
  );
  createHeaderCell(
    sheet.getCell(rowIndex, 6),
    "Комментарий",
    undefined,
    headerCellColor.hint
  );
  createHeaderCell(
    sheet.getCell(rowIndex, 7),
    "Дата",
    undefined,
    headerCellColor.hint
  );
  rowIndex += 1;

  for (let expense of expenses) {
    sheet.getCell(rowIndex, 2).value = expense.category;
    sheet.getCell(rowIndex, 3).value = expense.sum;
    sheet.getCell(rowIndex, 4).value = expense.moneyCode;
    sheet.getCell(rowIndex, 5).value = expense.description;
    sheet.getCell(rowIndex, 6).value = expense.comment;
    sheet.getCell(rowIndex, 7).value = expense.date;

    rowIndex += 1;
  }

  if (group) {
    setGroupedExpenses(sheet, expenses);
  }
  setPaymentsResult(
    sheet,
    expenses,
    false,
    resultTitle || "Итого по расходам",
    headerCellColor.negative
  );

  styleSheet(sheet, rowStart, 7);
}
