import { getEmptyReport } from "@/helpers/reports/getEmptyReport";
import { countReportBalance } from "@/helpers/reports/countReportBalance";
import { paymentTableType } from "@/helpers/paymentTableType";
import { checklistSelectOptions as selectOptions } from "@/helpers/checklistSelectOptions";

export const getEmptyChecklist = () => {
  return [
    {
      header: "Регистрация в МЧС",
      hint: "Комментарии, номер группы",
      result: selectOptions[2],
      comment: "",
    },
    {
      header: "ТБ фото отправлено на tb@vpoxod.ru",
      hint: "Комментарии",
      result: selectOptions[2],
      comment: "",
    },
    {
      header: "Все прошло хорошо",
      hint: "Комментарии",
      result: selectOptions[2],
      comment: "",
    },
    {
      header: "Фотоальбом есть",
      hint: "Комментарии, ссылки",
      result: selectOptions[2],
      comment: "",
    },
    {
      header: "Посты в соцсетях есть",
      hint: "Комментарии, ссылки",
      result: selectOptions[2],
      comment: "",
    },
    {
      header: "С общественным снаряжением все четко",
      hint: "Комментарии",
      result: selectOptions[2],
      comment: "",
    },
  ];
};

const getPayments = ({ sheet, paymentsLabel }) => {
  const payments = [];
  let paymentsStartRow = 0;

  for (let row = 1; row <= sheet.rowCount; row++) {
    const label = sheet.getCell(row, 1).value;

    if (
      label &&
      label.replaceAll(" ", "").toLowerCase() ===
        paymentsLabel.replaceAll(" ", "").toLowerCase()
    ) {
      paymentsStartRow = row + 2;
      break;
    }
  }

  if (paymentsStartRow === 0) {
    return;
  }

  while (true) {
    if (!sheet.getCell(paymentsStartRow, 4).value) {
      break;
    }

    const payment = {};

    if (
      sheet.getCell(paymentsStartRow, 2).value &&
      sheet.getCell(paymentsStartRow, 2).value.includes &&
      sheet.getCell(paymentsStartRow, 2).value.includes("]")
    ) {
      payment.name = sheet
        .getCell(paymentsStartRow, 2)
        .value.split("]")[1]
        .trim();
      payment.instructorId = Number(
        sheet
          .getCell(paymentsStartRow, 2)
          .value.split("]")[0]
          .replaceAll("[", "")
      );
    } else if (sheet.getCell(paymentsStartRow, 2).value) {
      payment.name = sheet.getCell(paymentsStartRow, 2).value;
    }
    payment.sum = Number(sheet.getCell(paymentsStartRow, 3).value);
    payment.moneyCode = sheet.getCell(paymentsStartRow, 4).value;
    payment.date = sheet.getCell(paymentsStartRow, 5).value;
    payment.comment = sheet.getCell(paymentsStartRow, 6).value;
    if (sheet.getCell(paymentsStartRow, 7).value) {
      payment.description = sheet.getCell(paymentsStartRow, 7).value;
    }

    payments.push(payment);
    paymentsStartRow++;
  }

  return payments;
};

export const parseV1 = async (report) => {
  const sheet = await report.getWorksheet(1);

  const result = getEmptyReport();

  result.hikeId = sheet.getCell(2, 2).value;
  result.name = sheet.getCell(3, 2).value;
  result.dates = sheet.getCell(4, 2).value;
  result.members = sheet.getCell(6, 2).value;
  result.inactiveMembers = sheet.getCell(7, 2).value;

  let row = 10;
  const moneyCodes = [];

  while (true) {
    const moneyCode = sheet.getCell(row, 5).value;

    if (!moneyCode) {
      break;
    }

    moneyCodes.push(moneyCode);
    row += 1;
  }

  result.moneyCodes = moneyCodes;

  const moneySums = {};

  const moneySumsSheet = await report.getWorksheet(2);

  let moneySumRow = 3;

  while (true) {
    const sum = moneySumsSheet.getCell(moneySumRow, 3).value;
    const moneyCode = moneySumsSheet.getCell(moneySumRow, 4).value;

    if (!moneyCode) {
      break;
    }

    moneySums[moneyCode] = sum;

    moneySumRow++;
  }

  result.moneySums = moneySums;

  const checklistSheet = await report.getWorksheet(4);
  const checklist = getEmptyChecklist();

  for (let row = 1; row <= checklistSheet.rowCount; row++) {
    const header = checklistSheet.getCell(row, 1).value;
    const result = checklistSheet.getCell(row, 2).value;
    const comment = checklistSheet.getCell(row, 3).value;

    const targetChecklistIndex = checklist.findIndex((checklistItem) => {
      if (!checklistItem.header || !header) {
        return false;
      }

      return (
        checklistItem.header.replaceAll(" ", "").toLowerCase() ===
        header.replaceAll(" ", "").toLowerCase()
      );
    });

    if (targetChecklistIndex === -1) {
      continue;
    }

    checklist[targetChecklistIndex] = {
      ...checklist[targetChecklistIndex],
      header,
      result,
      comment,
    };
  }

  result.checklist = checklist;

  const conversions = [];
  const conversionSheet = await report.getWorksheet(3);
  let conversionStartRow = 0;

  for (let row = 1; row <= conversionSheet.rowCount; row++) {
    const label = conversionSheet.getCell(row, 1).value;

    if (label && label.replaceAll(" ", "").toLowerCase() === "конвертация") {
      conversionStartRow = row + 2;
      break;
    }
  }

  while (true) {
    if (!conversionSheet.getCell(conversionStartRow, 2).value) {
      break;
    }

    const from = {
      sum: Number(
        conversionSheet.getCell(conversionStartRow, 2).value.split(" ")[0]
      ),
      moneyCode: conversionSheet
        .getCell(conversionStartRow, 2)
        .value.split(" ")[1],
    };
    const to = {
      sum: Number(
        conversionSheet.getCell(conversionStartRow, 3).value.split(" ")[0]
      ),
      moneyCode: conversionSheet
        .getCell(conversionStartRow, 3)
        .value.split(" ")[1],
    };
    const date = conversionSheet.getCell(conversionStartRow, 5).value;
    const comment = String(
      conversionSheet.getCell(conversionStartRow, 6).value
    );

    conversions.push({
      from,
      to,
      comment,
      date,
    });

    conversionStartRow++;
  }

  result.conversions = conversions;

  const expenses = [];
  const expensesSheet = await report.getWorksheet(3);
  let expensesSheetStartRow = 0;

  for (let row = 1; row <= expensesSheet.rowCount; row++) {
    const label = expensesSheet.getCell(row, 1).value;

    if (label && label.replaceAll(" ", "").toLowerCase() === "расходы") {
      expensesSheetStartRow = row + 2;
      break;
    }
  }

  while (true) {
    const category = expensesSheet.getCell(expensesSheetStartRow, 2).value;
    const sum = expensesSheet.getCell(expensesSheetStartRow, 3).value;
    const moneyCode = expensesSheet.getCell(expensesSheetStartRow, 4).value;
    const date = expensesSheet.getCell(expensesSheetStartRow, 5).value;
    const comment = expensesSheet.getCell(expensesSheetStartRow, 6).value;
    const description = expensesSheet.getCell(expensesSheetStartRow, 7).value;

    if (!moneyCode || !category) {
      break;
    }

    expenses.push({
      category,
      sum,
      moneyCode,
      comment,
      date,
      description,
    });

    expensesSheetStartRow++;
  }

  result.expenses = expenses;

  result.balance = countReportBalance(result);

  const incomingPaymentsSheet = await report.getWorksheet(2);

  result.incomingPayments = Array.from(
    result.incomingPayments.map((payment) => {
      return {
        ...payment,
        payments: getPayments({
          sheet: incomingPaymentsSheet,
          paymentsLabel: payment.label,
        }),
      };
    })
  );

  const mainSheet = await report.getWorksheet(1);
  const outgoingPaymentsSheet = await report.getWorksheet(3);

  result.outgoingPayments = Array.from(
    result.outgoingPayments.map((payment) => {
      if (payment.type === paymentTableType.instructorsRevenue) {
        return {
          ...payment,
          payments: getPayments({
            sheet: outgoingPaymentsSheet,
            paymentsLabel: payment.label,
          }),
        };
      }

      return {
        ...payment,
        payments: getPayments({
          sheet: mainSheet,
          paymentsLabel: payment.label,
        }),
      };
    })
  );

  return result;
};
