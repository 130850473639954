export const reportsColumns = [
  {
    align: "left",
    field: "checked",
    name: "checked",
    label:
      "Напротив отчета проставляется галочка, если в отчете Итого на руках во всех валютах равно нулю",
  },
  {
    align: "left",
    field: "name",
    label: "Маршрут",
    name: "report-name",
  },
  {
    align: "left",
    field: "dates",
    label: "Сроки",
    name: "report-dates",
  },
  {
    align: "left",
    field: "edit-report",
    name: "edit-report",
  },
];
