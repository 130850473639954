<template>
  <div :class="{ drawer: true, open: props.isOpen, close: !props.isOpen }">
    <div class="content relative-position">
      <calculator-keyboard
        v-if="state.isModalOpen"
        :initial-value="props.initialValue"
        :on-confirm="props.onConfirm"
      />
      <q-icon
        name="close"
        class="icon icon-close text-grey-8"
        size="sm"
        @click="props.close"
      />
    </div>
  </div>
  <div v-if="props.isOpen" class="bg" />
</template>

<script setup>
import CalculatorKeyboard from "./CalculatorKeyboard.vue";
import { reactive, watchEffect } from "vue";

const state = reactive({
  isModalOpen: false,
});

watchEffect(() => {
  if (props.isOpen) {
    state.isModalOpen = true;
  } else {
    setTimeout(() => {
      state.isModalOpen = false;
    }, 300);
  }
});

const props = defineProps({
  onConfirm: {
    type: Function,
    required: true,
  },
  close: {
    type: Function,
    required: true,
  },
  isOpen: {
    type: Boolean,
    default: false,
  },
  initialValue: {
    type: String,
    default: "0",
  },
});
</script>

<style scoped>
.content {
  max-width: 700px;
  width: 100vw;
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px 20px 0 0;
  padding-bottom: 80px;
}

.drawer {
  display: flex;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 10001;
  transform: translateY(100vh);
  transition: 0.3s ease-out;
}

.bg {
  z-index: 10000;
  background: rgba(0, 0, 0, 0.58);
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
}

.open {
  transform: translateY(0);
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: all ease-in 200ms;
  cursor: pointer;
  position: absolute;
  right: 4px;
  border: solid 1px transparent;
  top: -60px;
  left: 10px;
  background-color: #f5f2ec;
}

.icon:active {
  background: rgba(128, 128, 128, 0.8);
  border: solid 1px rgba(128, 128, 128, 0.6);
}

@media (hover: hover) and (pointer: fine) {
  .icon:hover {
    background: rgba(128, 128, 128, 0.8);
    border: solid 1px rgba(128, 128, 128, 0.6);
  }
}
</style>
