import { store } from "@/store/store";
import { getReportIndex } from "./getReportIndex";

export const promoteToCurrent = (report) => {
  if (store.currentReport?.key === report.key) {
    return;
  }

  const index = getReportIndex(report);

  if (
    store.currentReport &&
    store.currentReport?.name &&
    store.currentReport?.dates
  ) {
    store.allReports.reports.push(store.currentReport);
  }

  if (index !== -1) {
    store.allReports.reports.splice(index, 1);
  }

  store.currentReport = report;
};
