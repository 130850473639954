import Excel from "exceljs";
import { parseV2 } from "@/helpers/reportParser/reportParserV2";
import { parseV1 } from "@/helpers/reportParser/reportParserV1";

export const parseHikeReport = async (buffer) => {
  const workbook = new Excel.Workbook();
  const report = await workbook.xlsx.load(buffer);
  const sheet = await report.getWorksheet(1);

  const version = Number(sheet.getCell(1, 1).value.replace("v", ""));

  if (version >= 2) {
    return await parseV2(report);
  } else {
    return await parseV1(report);
  }
};
