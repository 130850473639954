<template>
  <div class="text-bold q-mt-sm text-h5 q-mx-sm q-mb-md no-pointer-events">
    Калькулятор
  </div>
  <div class="q-mx-sm q-mb-sm relative-position">
    <q-icon
      name="cancel"
      color="grey"
      class="icon text-grey-8"
      size="sm"
      @click="onClear"
    />
    <q-input
      class="no-pointer-events"
      label="Выражение"
      :model-value="expression"
      outlined
    />
  </div>
  <div class="q-mx-sm">
    <q-btn
      class="full-width"
      color="orange"
      label="Готово"
      no-caps
      @click="onConfirmClick"
    />
  </div>
  <div ref="calcRoot" class="q-mt-md q-mx-sm"></div>
</template>

<script setup>
import { KeyboardCalculator } from "calculator-keyboard";
import { computed, onMounted, onUnmounted, reactive, ref } from "vue";

const props = defineProps({
  onConfirm: {
    type: Function,
    required: true,
  },
  initialValue: {
    type: String,
    default: "0",
  },
});

const calcRoot = ref();
const state = reactive({
  calc: undefined,
  state: [props.initialValue],
});
const expression = computed(() => {
  if (!state.state) {
    return props.initialValue;
  }

  return state.state.join(" ");
});

const onClear = () => {
  state.state = [props.initialValue];
  state.calc.stack = [props.initialValue];
};

const onConfirmClick = () => {
  props.onConfirm({
    countedValue: state.calc.getCurrentCountedValue(),
  });
};

onMounted(() => {
  state.calc = new KeyboardCalculator(calcRoot.value);
  state.calc.stack = [props.initialValue];
  state.calc.onUpdate = ({ processedExpression }) => {
    state.state = processedExpression;
  };
});

onUnmounted(() => {
  state.calc.destroy();
});
</script>

<style scoped>
.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: all ease-in 200ms;
  cursor: pointer;
  position: absolute;
  right: 4px;
  border: solid 1px transparent;
  top: calc(50% - 20px);
}

.icon:active {
  background: rgba(128, 128, 128, 0.4);
  border: solid 1px rgba(128, 128, 128, 0.6);
}

@media (hover: hover) and (pointer: fine) {
  .icon:hover {
    background: rgba(128, 128, 128, 0.4);
    border: solid 1px rgba(128, 128, 128, 0.6);
  }
}
</style>
