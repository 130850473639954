<template>
  <section class="common-info-item">
    <title-label title="Маршрут" :label="store.currentReport?.name" />
  </section>
  <section class="common-info-item">
    <title-label title="Сроки" :label="datesLabel" />
  </section>
  <section class="common-info-item">
    <title-label title="Длительность" :label="durationLabel" />
  </section>
  <section class="common-info-item">
    <title-label
      title="Реальное количество участников"
      :label="store.currentReport?.members"
    />
  </section>
  <section class="common-info-item">
    <title-label
      title="Не явившиеся участники (ФИО)"
      :label="store.currentReport.inactiveMembers"
    />
  </section>
  <section class="common-info-item">
    <title-label title="Валюты" :label="moneyCodesLabel" />
  </section>
  <section class="edit-btn flex justify-center flex-center">
    <q-btn no-caps color="orange" @click="onOpenModalButtonClick">
      Изменить общие данные
    </q-btn>
  </section>
</template>

<script setup>
import { store } from "@/store/store";
import { computed } from "vue";
import TitleLabel from "@/components/titleLabel/TitleLabel.vue";
import { getFormattedReportDates } from "@/helpers/getFormatedReportDates";
import moment from "moment";
import { openModalPage } from "@/modalPages/helpers/openModalPage";
import { modalName } from "@/modalPages/helpers/modalName";

const moneyCodesLabel = computed(() => {
  if (!store.currentReport?.moneyCodes) {
    return "";
  }

  return store.currentReport?.moneyCodes.join(", ");
});

const datesLabel = computed(() => {
  if (!store.currentReport?.dates) {
    return "";
  }

  return getFormattedReportDates(store.currentReport?.dates);
});

const durationLabel = computed(() => {
  if (!store.currentReport?.dates) {
    return "";
  }

  const startDate = moment(
    store.currentReport?.dates.split("-")[0].replaceAll(" ", ""),
    "DD.MM.YYYY"
  );
  const endDate = moment(
    store.currentReport?.dates.split("-")[1].replaceAll(" ", ""),
    "DD.MM.YYYY"
  );

  return `${endDate.diff(startDate, "days") + 1} д.`;
});

const onOpenModalButtonClick = () => {
  openModalPage(modalName.modalCurrentReport, {
    report: JSON.parse(JSON.stringify(store.currentReport)),
    saveData: (report) => {
      store.currentReport = report;
    },
  });
};
</script>

<style scoped>
.common-info-item {
  margin: 15px 0 0 0;
}

.edit-btn {
  margin: 30px 0 0 0;
}
</style>
