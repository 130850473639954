<template>
  <text-header hint="(включая оплаты с исключениями)" :description="unitsDescriptions.clientsSum.description">
    Общая сумма оплат участников
  </text-header>
  <p v-if="!store.currentReport.moneyCodes.length">Не добавлено валют</p>
  <div class="item">
    <money-codes-table :rows="moneyCodeRows" />
  </div>
  <notification-data-saved v-if="isNotificationVisible" />
  <payments-information
    :payments="store.currentReport.incomingPayments"
    :money-codes="store.currentReport.moneyCodes"
    :tables-with-negatives-sums="[paymentTableType.instructors]"
  />
</template>

<script setup>
import { store } from "@/store/store";
import TextHeader from "@/components/textHeader/TextHeader";
import PaymentsInformation from "@/components/paymentsInformation/PaymentsInformation";
import NotificationDataSaved from "@/components/notificationDataSaved/NotificationDataSaved";
import { useMutationEmitted } from "@/hooks/useMutationEmitted";
import { unitsDescriptions } from "@/helpers/unitsDescriptions";
import { computed } from "vue";
import MoneyCodesTable from "@/pages/currentReport/panels/payments/components/MoneyCodesTable.vue";
import { paymentTableType } from "@/helpers/paymentTableType";

const isNotificationVisible = useMutationEmitted(store.currentReport.moneySums);

const moneyCodeRows = computed(() => {
  if (!store.currentReport?.moneyCodes || !store.currentReport?.moneySums) {
    return [];
  }

  const result = store.currentReport.moneyCodes.map((moneyCode) => {
    return {
      sum: store.currentReport.moneySums[moneyCode],
      moneyCode,
    };
  });

  return Array.from(result);
});
</script>

<style scoped>
.item {
  margin-bottom: 10px;
}
</style>
