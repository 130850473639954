<template>
  <div class="button">
    <q-btn
      class="full-width"
      no-caps
      color="orange"
      @click="onMainReportDownload"
    >
      Скачать общий отчёт
    </q-btn>
  </div>
</template>

<script setup>
import { downloadMainReport } from "@/helpers/downloadMainReport";
import { isReportValidForProcessing } from "@/helpers/reports/isReportValidForProcessing";
import { store } from "@/store/store";
import { appAlert } from "@/components/appAlert/appAlert";

const onMainReportDownload = async () => {
  const reportData = JSON.parse(JSON.stringify(store.allReports));
  if (store.currentReport) {
    reportData.reports.push(store.currentReport);
  }
  const { isValid, reason } = isReportValidForProcessing(reportData);

  if (!isValid) {
    await appAlert(reason);
    return;
  }

  downloadMainReport();
};
</script>

<style scoped>
.button {
  display: flex;
  justify-content: center;
  margin: 3em 0;
}
</style>
