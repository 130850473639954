<template>
  <text-header> Загрузка отчета с гугла диска в приложение </text-header>
  <q-input
    v-model="state.link"
    outlined
    class="item"
    label="Ссылка на гугл таблицу с отчетом"
  />
  <!--  <input type="file" :onchange="onLoadFile" />-->

  <div class="btn">
    <q-btn
      :disable="isLinkInvalid"
      class="item"
      no-caps
      color="orange"
      :loading="state.isLoading"
      @click="onLoad"
    >
      Загрузить
    </q-btn>
  </div>
</template>

<script setup>
import axios from "axios";
import { parseHikeReport } from "@/helpers/reportParser";
import TextHeader from "@/components/textHeader/TextHeader.vue";
import { computed, reactive } from "vue";
import { appAlert } from "@/components/appAlert/appAlert";
import { store } from "@/store/store";
import { appConfirm } from "@/components/appConfirm/appConfirm";
import { promoteToCurrent } from "@/pages/allReports/panels/routesList/helpers/promoteToCurrent";
import { closeModalPage } from "@/modalPages/helpers/closeModalPage";

const state = reactive({
  link: "",
  isLoading: false,
});

const isLinkInvalid = computed(() => {
  return (
    !state.link.includes("https://docs.google.com/spreadsheets/d/") ||
    state.link.replace("https://docs.google.com/spreadsheets/d", "").split("/")
      .length < 2
  );
});

const reportId = computed(() => {
  if (isLinkInvalid.value) {
    return "";
  }

  return state.link
    .replace("https://docs.google.com/spreadsheets/d", "")
    .split("/")[1];
});

function base64ToArrayBuffer(base64) {
  const binaryString = atob(base64);
  const bytes = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}

const onLoadFile = async (event) => {
  const [file] = event.target.files;
  let reader = new FileReader();

  reader.onload = async function (e) {
    let arrayBuffer = new Uint8Array(reader.result);
    const report = await parseHikeReport(arrayBuffer);
    console.log(report);
    store.currentReport = report;
  };

  reader.readAsArrayBuffer(file);
};

const onLoad = async () => {
  try {
    state.isLoading = true;
    const { data } = await axios.post("/api/download-report", {
      reportId: reportId.value,
    });

    const report = await parseHikeReport(base64ToArrayBuffer(data.data));

    state.link = "";

    if (
      report.hikeId === store.currentReport?.hikeId ||
      store.allReports.reports.find(({ hikeId }) => report.hikeId === hikeId)
    ) {
      const isExchangeReport = await appConfirm(
        "Отчет с таким походом и датами уже существует. Заменить?"
      );

      if (!isExchangeReport) {
        return;
      }

      if (isExchangeReport && report.hikeId === store.currentReport?.hikeId) {
        store.currentReport = report;
      } else if (isExchangeReport) {
        const targetIndex = store.allReports.reports.findIndex(
          ({ hikeId }) => report.hikeId === hikeId
        );
        store.allReports.reports[targetIndex] = report;
      }
    }

    promoteToCurrent(report);
    closeModalPage();
  } catch (err) {
    appAlert("Не удалось загрузить отчет!");
    console.log(err);
  } finally {
    state.isLoading = false;
  }
};
</script>

<style scoped>
.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}
</style>
