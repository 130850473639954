import ExcelJS from "exceljs";
import { createHeaderCell, styleSheet } from "@/helpers/excel/helpers";
import {
  setCommonPayments,
  setConversions,
  setExpenses,
  setHikeInformation,
  setInstructorsRevenue,
  setPayments,
} from "@/helpers/excel/sheetDataSetters";
import { setDivider } from "@/helpers/excel/helpers/setDivider";
import { store } from "@/store/store";
import { countAllReportsFinalConvertedSum } from "@/helpers/reports/countAllReportsFinalConvertedSum";
import { getHikeName } from "@/helpers/excel/helpers/getHikeName";
import { headerCellColor } from "@/helpers/excel/helpers/createHeaderCell";

export const getMainReport = (reportData) => {
  const workbook = new ExcelJS.Workbook();

  const sheet = workbook.addWorksheet("Общее");

  let rowIndex = 1;
  sheet.getCell(1, 1).value = `v${store.reportsVersion}`;
  rowIndex += 1;

  const startIndex = rowIndex;
  createHeaderCell(sheet.getCell(rowIndex, 1), "Общий баланс инструктора");
  createHeaderCell(sheet.getCell(rowIndex, 2), "Баланс в валюте");
  createHeaderCell(sheet.getCell(rowIndex, 3), "Баланс в рублях");
  createHeaderCell(sheet.getCell(rowIndex, 4), "Дата курса");
  rowIndex += 1;

  const balanceEntries = [...Object.entries(reportData.balance)];
  for (let [moneyCode, balanceValue] of balanceEntries) {
    sheet.getCell(rowIndex, 2).value = balanceValue.sum + " " + moneyCode;
    sheet.getCell(rowIndex, 3).value =
      moneyCode === "RUB" ? balanceValue.sum : balanceValue.convertedSum || "-";
    sheet.getCell(rowIndex, 4).value = balanceValue.date || "-";
    rowIndex += 1;
  }

  createHeaderCell(sheet.getCell(rowIndex, 1), "Итого по инструктору");
  createHeaderCell(sheet.getCell(rowIndex, 2), "Итого");
  sheet.getCell(rowIndex, 3).value = countAllReportsFinalConvertedSum(
    reportData.balance
  );
  styleSheet(sheet, startIndex, 4);

  setDivider(sheet);
  setPayments(sheet, reportData.incomingPayments, headerCellColor.positive);

  setDivider(sheet);
  setExpenses(
    sheet,
    reportData.expenses,
    false,
    "Общие расходы",
    headerCellColor.negative,
    "Итого по общим расходам"
  );

  setDivider(sheet);
  setPayments(sheet, reportData.outgoingPayments, headerCellColor.negative);

  for (let report of reportData.reports) {
    const sheet = workbook.addWorksheet(getHikeName(report));

    setHikeInformation(sheet, report);
    setDivider(sheet);
    setPayments(
      sheet,
      report.outgoingPayments.filter(
        (payment) => payment.label !== "Доходы инструктора"
      ),
      headerCellColor.negative
    );
    setDivider(sheet);

    const start = sheet.rowCount + 1;
    setCommonPayments(sheet, report.moneySums);
    setPayments(sheet, [report.incomingPayments[0]], undefined, false);
    styleSheet(sheet, start, 6);
    setDivider(sheet);

    setPayments(
      sheet,
      report.incomingPayments.slice(1),
      headerCellColor.positive
    );
    setDivider(sheet);

    setExpenses(sheet, report.expenses);
    setDivider(sheet);

    setInstructorsRevenue(sheet, report.outgoingPayments);
    setDivider(sheet);
    // setGroupedExpenses(sheet, groupExpenses(report.expenses));
    // setPaymentsResult(sheet, [
    //   ...groupExpenses(report.expenses).filter(
    //     ({ isUncountable }) => !isUncountable
    //   ),
    // ]);
    // setDivider(sheet);
    setConversions(sheet, report.conversions);
    setDivider(sheet, 3);

    const rowChecklistStart = sheet.rowCount + 1;
    let rowIndex = rowChecklistStart;
    report.checklist.forEach((checkListItem, index) => {
      createHeaderCell(
        sheet.getCell(rowIndex + index, 1),
        checkListItem.header
      );
      sheet.getCell(rowIndex + index, 2).value = checkListItem.result;
      sheet.getCell(rowIndex + index, 5).value = checkListItem.comment || " ";
    });
    styleSheet(sheet, rowChecklistStart, 5);
  }

  return workbook.xlsx.writeBuffer();
};
