import { nanoid } from "nanoid";
import { unitsDescriptions } from "@/helpers/unitsDescriptions";
import { paymentTableType } from "@/helpers/paymentTableType";
import { getEmptyChecklist } from "@/helpers/reports/getEmptyChecklist";

export const getEmptyReport = () => {
  return {
    key: nanoid(),
    name: "",
    dates: "",
    hikeId: "",
    moneySums: {},
    moneyCodes: ["RUB"],
    members: "",
    routeId: "",
    coordinator: "",
    inactiveMembers: "",
    expenses: [],
    conversions: [],
    checklist: getEmptyChecklist(),
    incomingPayments: [
      {
        type: paymentTableType.members,
        label: "Оплаты участников с исключениями",
        description: unitsDescriptions.clientsSumWithExceptions.description,
        payments: [],
      },
      {
        type: paymentTableType.instructors,
        label: "Получено от инструкторов",
        description: unitsDescriptions.fromInstructors.description,
        payments: [],
      },
      {
        type: paymentTableType.tutors,
        label: "Получено от кураторов",
        description: unitsDescriptions.fromTutors.description,
        payments: [],
      },
      {
        type: paymentTableType.office,
        label: "Получено из офиса",
        description: unitsDescriptions.fromOffice.description,
        payments: [],
      },
    ],
    outgoingPayments: [
      {
        type: paymentTableType.instructorsRevenue,
        label: "Доходы инструктора",
        description: unitsDescriptions.instructorRevenue.description,
        payments: [],
      },
      {
        type: paymentTableType.instructors,
        label: "Передано инструкторам",
        description: unitsDescriptions.toInstructors.description,
        payments: [],
      },
      {
        type: paymentTableType.tutors,
        label: "Передано кураторам",
        description: unitsDescriptions.toTutors.description,
        payments: [],
      },
      {
        type: paymentTableType.office,
        label: "Передано в офис",
        description: unitsDescriptions.toOffice.description,
        payments: [],
      },
    ],
  };
};
