import { unitsDescriptions } from "@/helpers/unitsDescriptions";
import { paymentTableType } from "@/helpers/paymentTableType";

export const allReports = {
  reports: [],
  balance: {},
  expenses: [],
  incomingPayments: [
    {
      type: paymentTableType.instructors,
      label: "Получено от инструкторов",
      description: unitsDescriptions.fromInstructors.description,
      payments: [],
    },
    {
      type: paymentTableType.tutors,
      label: "Получено от кураторов",
      description: unitsDescriptions.fromTutors.description,
      payments: [],
    },
    {
      type: paymentTableType.office,
      label: "Получено из офиса",
      description: unitsDescriptions.fromOffice.description,
      payments: [],
    },
  ],
  outgoingPayments: [
    {
      type: paymentTableType.instructors,
      label: "Передано инструкторам",
      description: unitsDescriptions.toInstructors.description,
      payments: [],
    },
    {
      type: paymentTableType.tutors,
      label: "Передано кураторам",
      description: unitsDescriptions.toTutors.description,
      payments: [],
    },
    {
      type: paymentTableType.office,
      label: "Передано в офис",
      description: unitsDescriptions.toOffice.description,
      payments: [],
    },
  ],
};
