export const countResultForMoneyCode = (currentReport, code) => {
  let result = currentReport.moneySums[code]
    ? Number(currentReport.moneySums[code])
    : 0;

  const instructorsRevenues = currentReport.outgoingPayments[0].payments;

  for (let payment of instructorsRevenues) {
    if (payment.moneyCode === code) {
      console.log(payment)
      result -= Number(payment.sum) +
        Number(payment.soldItemsSum || 0) +
        Number(payment.bonusSum || 0);
    }
  }

  for (let expense of currentReport.expenses) {
    if (expense.moneyCode === code) {
      result -= Number(expense.sum);
    }
  }

  for (let conversion of currentReport.conversions) {
    if (conversion.to.moneyCode === code) {
      result += Number(conversion.to.sum);
    } else if (conversion.from.moneyCode === code) {
      result -= Number(conversion.from.sum);
    }
  }

  return result;
};
