<template>
  <section class="content container">
    <text-header class="item">
      Хотите сообщить об ошибке или оставить отзыв о работе приложения?
    </text-header>
    <q-btn
      class="item"
      color="orange"
      icon="telegram"
      label="Написать фидбэк"
      no-caps
      @click="onFeedbackButtonClick"
    />
    <app-informer class="item">
      <p>
        Вы будете перенаправлены в телеграм, где сможете прикрепить необходимые
        скриншоты или видео, и отправить сообщение об ошибке или пожелании нам.
        <br />
        Мы обязательно на него ответим!
      </p>
    </app-informer>
  </section>
  <input v-if="store.testParsing" type="file" :onchange="onChange" />
</template>

<script setup>
import TextHeader from "@/components/textHeader/TextHeader";
import { reactive } from "vue";
import AppInformer from "@/components/appInformer/AppInformer";
import { parseHikeReport } from "@/helpers/reportParser";
import { store } from "@/store/store";
import {appConfirm} from "@/components/appConfirm/appConfirm";

const errorData = reactive({
  name: "",
  description: "",
});

const onFeedbackButtonClick = () => {
  window.open(process.env.VUE_APP_TELEGRAM_FEEDBACK);
};

function file2Buffer(file) {
  return new Promise(function (resolve, reject) {
    const reader = new FileReader();
    const readFile = function (event) {
      const buffer = reader.result;
      resolve(buffer);
    };

    reader.addEventListener("load", readFile);
    reader.readAsArrayBuffer(file);
  });
}

const onChange = async (event) => {
  const buffer = await file2Buffer(event.target.files[0]);
  const report = await parseHikeReport(buffer);

  console.log(await parseHikeReport(buffer), 'here');

  if (await appConfirm('добавить в текущие отчеты?')) {
    store.allReports.reports = [...store.allReports.reports, report];
  }
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.item {
  margin-bottom: 30px;
}
</style>
