const allMoneyCodesHasConversion = (balance) => {
  let flag = true;

  [...Object.entries(balance)].forEach(([moneyCode, currentValue]) => {
    if (
      moneyCode !== "RUB" &&
      !currentValue.convertedSum &&
      !(!currentValue.isConvertable && currentValue?.sum === 0)
    ) {
      flag = false;
    }
  });

  return flag;
};

export const countAllReportsFinalConvertedSum = (balance) => {
  if (!allMoneyCodesHasConversion(balance)) {
    return "-";
  }

  return [...Object.entries(balance)].reduce(
    (previousValue, [moneyCode, currentValue]) => {
      let convertedSum = Number(
        (moneyCode === "RUB" ? currentValue.sum : currentValue.convertedSum) ||
          0
      );

      return {
        convertedSum: previousValue.convertedSum + Number(convertedSum || 0),
      };
    },
    { convertedSum: 0 }
  ).convertedSum;
};
