<template>
  <div>
    <p class="header">
      {{ props.header }}
    </p>
    <q-select
      :options="props.options || checklistSelectOptions"
      class="select"
      outlined
      :model-value="props.result"
      size="sm"
      @update:model-value="updateResult"
    />
    <div class="comment">
      <check-list-table
        :rows="[{ comment: props.comment }]"
        :hint="props.hint"
        :edit="onUpdateCommentClick"
      />
    </div>
  </div>
</template>

<script setup>
import { modalName } from "@/modalPages/helpers/modalName";
import { openModalPage } from "@/modalPages/helpers/openModalPage";
import { checklistSelectOptions } from "@/helpers/checklistSelectOptions";
import CheckListTable from "@/pages/currentReport/panels/checkList/components/CheckListTable.vue";

const props = defineProps({
  header: {
    type: String,
    required: true,
  },
  options: {
    type: [String],
    required: false,
  },
  result: {
    type: String,
    required: true,
  },
  hint: {
    type: String,
    default: () => "Комментарии",
  },
  comment: {
    type: String,
    required: true,
  },
  putItem: {
    type: Function,
    default: () => {},
  },
});

// const isCommentEditDisabled = computed(() => {
//   return !props.result || props.result === checklistSelectOptions[2];
// });

const updateResult = (result) => {
  props.putItem({
    result,
  });
};

const onUpdateCommentClick = () => {
  openModalPage(modalName.modalComment, {
    saveData: (comment) =>
      props.putItem({
        comment,
      }),
    placeholder: "",
    comment: props.comment,
    header: !!props.comment
      ? "Добавить комментарий"
      : "Редактировать комментарий",
  });
};
</script>

<style scoped>
.header {
  font-weight: 600;
  font-size: 15px;
}

.select {
  width: 100%;
}

.comment {
  margin-top: 15px;
}
</style>
