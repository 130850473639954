export const headerCellColor = {
  positive: "d9ead3",
  negative: "f4cccc",
  hint: "7d7d7d",
};

export function createHeaderCell(cell, value, color, fontColor) {
  cell.value = value;
  cell.font = {
    bold: true,
    color: { argb: fontColor || "00000" },
  };
  cell.fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: color || "b7b7b7" },
  };
  cell.border = {
    top: { style: "thin", color: { argb: "a5a5a6" } },
    left: { style: "thin", color: { argb: "a5a5a6" } },
    bottom: { style: "thin", color: { argb: "a5a5a6" } },
    right: { style: "thin", color: { argb: "a5a5a6" } },
  };
}
