<template>
  <section>
    <routes-list-table
      :rows="rows"
      :columns="reportsColumns"
      :promote-to-current="promoteToCurrent"
      :remove="remove"
    />
    <button-add :handler="buttonAddHandler" />
    <div class="flex justify-center q-mt-lg">
      <app-informer class="flex justify-center q-mb-sm">
        <div>
          Если вдруг вы удалили отчет из приложения, можете попросить у
          координатора ссылку на синхронизированный вами отчет, чтобы вновь
          загрузить его в приложение и продолжить редактирование
        </div>
      </app-informer>
      <q-btn
        no-caps
        class="full-width"
        color="orange"
        icon="add"
        @click="onLoadReportButtonClick"
      >
        <div class="q-ml-md">Загрузить отчет по ссылке</div>
      </q-btn>
    </div>
  </section>
</template>

<script setup>
import { store } from "@/store/store";
import ButtonAdd from "@/components/buttonAdd/ButtonAdd";
import { reportsColumns } from "@/pages/allReports/panels/routesList/components/routesListTable/reportsColumns";
import { computed, watchEffect } from "vue";
import { openModalPage } from "@/modalPages/helpers/openModalPage";
import { modalName } from "@/modalPages/helpers/modalName";
import { compareFormattedDates } from "@/helpers/compareFormattedDates";
import { promoteToCurrent } from "@/pages/allReports/panels/routesList/helpers/promoteToCurrent";
import RoutesListTable from "@/pages/allReports/panels/routesList/components/routesListTable/RoutesListTable";
import { countReportBalance } from "@/helpers/reports/countReportBalance";
import { getReportIndex } from "@/pages/allReports/panels/routesList/helpers/getReportIndex";
import AppInformer from "@/components/appInformer/AppInformer.vue";

const onLoadReportButtonClick = () => {
  openModalPage(modalName.modalLoadReport, {});
};

const rows = computed(() => {
  const currentReport = store.currentReport;
  const reports = store.allReports.reports;

  const targetRows =
    currentReport?.name && currentReport?.dates
      ? [currentReport, ...reports]
      : reports;

  return [
    ...targetRows
      .sort((firstReport, secondReport) => {
        const firstReportStartDate = firstReport.dates.split("-")[0];
        const secondReportStartDate = secondReport.dates.split("-")[0];

        return compareFormattedDates(
          firstReportStartDate,
          secondReportStartDate
        );
      })
      .map((report) => ({
        ...report,
        checked:
          report.balance &&
          report.balance.every((balance) => +balance.finalResult === 0),
      })),
  ];
});

const remove = (report) => {
  const index = getReportIndex(report);

  if (index !== -1) {
    store.allReports.reports.splice(index, 1);
  } else {
    store.currentReport = undefined;
  }
};

const buttonAddHandler = () => {
  openModalPage(modalName.modalRoute, {
    onConfirm: (report) => {
      promoteToCurrent(report);
    },
  });
};

watchEffect(() => {
  for (const report of store.allReports.reports) {
    report.balance = countReportBalance(report);
  }

  store.currentReport.balance = countReportBalance(store.currentReport);
});
</script>

<style scoped>
.btn {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}
</style>
