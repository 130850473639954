<template>
  <app-informer>
    <p>
      <strong>Баланс =</strong> Сумма балансов на руках после передач по всем представленным в списке отчетам + Получено вне - Потрачено вне
    </p>
  </app-informer>
  <balance-table :rows="resultRows" :edit="editTime" />
  <button-download-report />
</template>

<script setup>
import { store } from "@/store/store";
import { computed, watchEffect } from "vue";
import { openModalPage } from "@/modalPages/helpers/openModalPage";
import ButtonDownloadReport from "./components/buttonDownloadReport/ButtonDownloadReport";
import { modalName } from "@/modalPages/helpers/modalName";
import { modifyBalance } from "@/pages/allReports/helpers";
import { formatNumber } from "@/helpers/formatNumber";
import AppInformer from "@/components/appInformer/AppInformer.vue";
import BalanceTable from "@/pages/allReports/panels/balance/components/balanceTable/BalanceTable";

const editTime = (moneyCode) => {
  openModalPage(modalName.modalTime, {
    onConfirm: async (day, month, year) => {
      store.allReports.balance[moneyCode].date = `${day}.${month}.${year}`;
      store.allReports.isBalanceLoading = true;
      await modifyBalance();
      store.allReports.isBalanceLoading = false;
    },
    header: "Редактировать дату",
  });
};

const resultRows = computed(() => {
  const rows = [];
  const entries = [...Object.entries(store.allReports.balance)];

  for (const [moneyCode, balance] of entries) {
    let convertedSum;

    if (moneyCode === "RUB") {
      convertedSum = formatNumber(balance.sum || 0) + " RUB";
    } else {
      convertedSum = balance.convertedSum
        ? formatNumber(balance.convertedSum || 0) + " RUB"
        : "";
    }

    rows.push({
      moneyCode,
      sum: formatNumber(balance.sum || 0) + " " + moneyCode,
      date: moneyCode === "RUB" ? "-" : balance.date,
      convertedSum,
      isConvertable: balance.isConvertable,
      originalBalance: balance,
    });
  }

  return rows;
});

watchEffect(async () => {
  store.allReports.isBalanceLoading = true;
  await modifyBalance();
  store.allReports.isBalanceLoading = false;
});
</script>

<style scoped></style>
