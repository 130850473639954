export const moneyCodesTableColumns = [
  {
    align: "center",
    name: "moneyCode",
    field: "moneyCode",
    label: "moneyCode",
  },
  {
    align: "center",
    name: "sum",
    field: "sum",
    label: "sum",
  },
  {
    align: "center",
    name: "edit",
    field: "edit",
  },
];
