import { checklistSelectOptions } from "@/helpers/checklistSelectOptions";

export const reasons = {
  checklistInvalid:
    "Чеклист не проставлен до конца. Вернитесь к нему, проставьте все пункты, потом возвращайтесь скачивать отчет",
};

export const isHikeReportValidForProcessing = (report) => {
  const result = {
    isValid: true,
    reason: "",
  };

  const isChecklistValid = report.checklist.reduce((prev, cur) => {
    if (!prev) {
      return prev;
    }

    return !(cur.result === checklistSelectOptions[0]);
  }, true);

  if (!isChecklistValid) {
    result.isValid = false;
    result.reason = reasons.checklistInvalid;
  }

  return result;
};

export const isReportValidForProcessing = (report) => {
  for (const targetReport of report.reports) {
    const res = isHikeReportValidForProcessing(targetReport);

    if (!res.isValid) {
      return res;
    }
  }

  return {
    isValid: true,
    reason: "",
  };
};
